@import '~resources/less/themes/base/variables.less';

#vip-tooltip {
    text-align: center;
    .vip-popup-star {
        margin-top: -60px;
    }

    &.mobile {
        .vip-popup-star {
            margin-top: 0;
        }
    }

    h3 {
        font-size: @fontSizeLarge;
    }

    .dialog-popup-body {
        padding-bottom: 12px;
        padding-top: 60px;
    }

    .dialog-popup-container {
        position: absolute;
        z-index: 1;
        right: 8px;
        top: 84px;
        overflow: visible;
    }

    .dialog-popup-container::before {
        border: solid transparent;
        content: " ";
        position: absolute;
        border-bottom-color: @white;
        border-width: 28px;
        margin-left: -106px;
        top: -46px
    }
}
