@import (reference) '~resources/less/themes/base/variables.less';

.caret-up-icon() {
    content: "\f0d8";
    font-family: FontAwesome;
    font-size: 20px;
    color: @white;
    position: absolute;
    right: 0;
    top: -5px;
}

.active-menu-item() {
    background: @white;
    color: @primaryGray;
}

.link-transition() {
    transition: color .15s linear;
}

.country-icon(@country, @size) {
    background: url('/assets/compiled/images/flags/@{size}/@{country}.png');
}

.countries-icons(@size) {
    .icon-country-us {
        .country-icon('United-States', @size);
    }
    .icon-country-at {
        .country-icon('Austria', @size);
    }
    .icon-country-de_AT {
        .country-icon('Germany', @size);
    }
    .icon-country-fr {
        .country-icon('France', @size);
    }
    .icon-country-de {
        .country-icon('Germany', @size);
    }
    .icon-country-uk {
        .country-icon('United-Kingdom', @size);
    }
    .icon-country-de_BE {
        .country-icon('Germany', @size);
    }
    .icon-country-be {
        .country-icon('Belgium', @size);
    }
    .icon-country-es {
        .country-icon('Spain', @size);
    }
    .icon-country-eu {
        .country-icon('European-Union', @size);
    }
    .icon-country-de_LU {
        .country-icon('Germany', @size);
    }
    .icon-country-lu {
        .country-icon('Luxembourg', @size);
    }
    .icon-country-nl {
        .country-icon('Netherlands', @size);
    }
    .icon-country-it {
        .country-icon('Italy', @size);
    }
    .icon-country-de_CH {
        .country-icon('Germany', @size);
    }
    .icon-country-ch {
        .country-icon('Switzerland', @size);
    }
    .icon-country-cz {
        .country-icon('Czech-Republic', @size);
    }
    .icon-country-hi {
        .country-icon('India', @size);
    }
    .icon-country-ta {
        .country-icon('India', @size);
    }
    .icon-country-pl {
        .country-icon('Poland', @size);
    }
    .icon-country-ru {
        .country-icon('Russia', @size);
    }
}
