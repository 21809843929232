@import '../variables.less';
@import '../mixins/responsive_utilities.less';

.shopper-approved {
    .onp-hidden(@xs: true);
    align-items: center;
    background-color: @primaryGrayDarker;
    color: @white;
    display: flex;
    flex-direction: row;
    font-family: @headingsFontFamily;
    justify-content: flex-start;
    line-height: 22px;
    margin: 10px 0;
    height: 50px;
    padding: 5px 0;
    font-size: @baseFontSize;
    .shopper-approved-rating-container {
        display: inline-block;
        text-align: center;
        margin: 0 20px;
        line-height: 115%;
        i {
            color: #ff9100;
            margin: 0 1px;
            font-size: 11px;
        }
    }
    .shopper-approved-rating-count {
        font-size: 150%;
    }
    .shopper-approved-rating-title {
        font-size: 100%;
        text-transform: uppercase;
    }
    .shopper-approved-payment-methods-container {
        margin: 0 0 0 auto;
        padding: 10px 15px 5px 0;

        .shopper-approved-payment-methods-image {
            max-width: 100%
        }

        .onp-style(@sm: {
            flex-basis: 300px;
        });
    }
}
