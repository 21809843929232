@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/responsive_utilities.less';

.cookie-policy {
    bottom: 0;
    position: fixed;
    text-align: center;
    width: 100%;
    background: @primaryBlue;
    z-index: @overlayZIndex - 1;
}

.cookie-policy-desc {
    color: @white;
    .onp-style (
        @xs: {
            font-size: @baseFontSize;
        },
        @non-xs: {
            font-size: 18px;
        },
        @big: {
            padding: 15px 35px;
        },
        @small: {
            padding: 0 35px;
        }
    );
    a {
        color: @primaryYellow;
    }
}

.cookie-policy-close {
    @cookie-policy-close-dimension: 26px;
    cursor: pointer;
    // font family for unicode characters 2700-27BF
    // otherwise changing color doesn't work in safari
    font: 28px/28px 'Zapf Dingbats';
    height: @cookie-policy-close-dimension;
    position: absolute;
    right: 10px;
    top: 10px;
    width: @cookie-policy-close-dimension;
    color: @white;
}
