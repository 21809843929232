@import "../../variables.less";

.text-simple {
    appearance: none !important;
    background: @white !important;
    border: 1px solid @primaryGrayLight !important;
    box-shadow: none !important;
    color: @primaryGray !important;
    flex: 1 1 auto !important;
    font: 14px @latoFontFamily !important;
    height: @formElementHeight !important;
    line-height: 26px !important;
    margin: 0 !important;
    outline: none;
    &:not(.no-default-padding) {
        padding: 5px 10px !important;
    }
    transition: border .15s linear !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    &:focus,
    &:hover {
        border-color: @primaryBlue !important;
        box-shadow: none !important;
    }
    &::placeholder {
        color: @primaryGrayLight2;
    }
}
