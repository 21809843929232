@import '~resources/less/themes/base/variables.less';

#vip-popup {
    text-align: center;
    .vip-popup-star {
        margin-top: -60px;
    }

    &.mobile {
        .vip-popup-star {
            margin-top: 0;
        }
    }

    h3 {
        font-size: @fontSizeLarge;
    }
    p {
        margin-bottom: 30px;
    }

    .dialog-popup-container,
    .dialog-popup-body {
        overflow: visible;
    }

    .dialog-popup-toolbar-close {
        margin-top: -22px;
    }

    .dialog-popup-container {
        position: absolute;
        transition: transform 1s;
        transform-origin: 50% 50%;
    }
}
