@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/responsive_utilities.less';

.dialog-popup-gray-header() {
    .dialog-popup-header-title {
        color: @primaryGray;
        font-size: 20px;
        text-align: left;
        text-transform: capitalize;
        font-weight: 700;
    }
}

.dialog-padding {
    .onp-style(
        @xs: {
            padding-left: 10px;
            padding-right: 10px;
        },
        @non-xs: {
            padding-left: 50px;
            padding-right: 50px;
        }
    );
}

.dialog-popup-mask {
    padding: 10px;
    position: fixed;
    z-index: @overlayZIndex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .3s ease;
}

.dialog-popup-container {
    max-width: 95%;
    padding: 3px;
    background-color: @white;
    border-radius: 2px;
    transition: all .3s ease;
    font: 14px @latoFontFamily;
    box-shadow: 0 0 8px rgba(68, 68, 68, 0.6);
    max-height: 100%;
    overflow: auto;
}

.dialog-popup-toolbar {
    z-index: @overlayZIndex + 1;
    position: absolute;
    right: 8px;
    top: 0;
}

.dialog-popup-toolbar-close {
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: url("/assets/images/site/onp_main_sprite.png") -292px -26px no-repeat;
}

.dialog-popup-header {
    position: relative;
    margin-top: 10px;
}

.dialog-popup-header-title {
    &:extend(.dialog-padding);
    padding-right: 30px;
    margin: 0 0 10px;
    font-family: @latoFontFamily;
    font-weight: normal;
    line-height: 1em;
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
}

.dialog-popup-body {
    &:extend(.dialog-padding);
    min-width: 100px;
    max-width: 100%;
    padding-bottom: 30px;
    overflow: auto;
}

.dialog-popup-footer {
    &:extend(.dialog-padding);
    padding-bottom: 30px;
}
