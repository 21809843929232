@import '~opensoft-jquery.royalslider/royalslider.css';
@import '~opensoft-jquery.royalslider/skins/default/rs-default.css';
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
/*
 *  Responsive Utilities
 */
#royalSlider-trimmer {
  overflow: hidden;
  height: 400px;
}
@media (max-width: 767px) {
  #royalSlider-trimmer {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #royalSlider-trimmer {
    display: none;
  }
}
#royalSlider-trimmer .royalSlider-container {
  position: relative;
}
#royalSlider-trimmer .royalSlider-container .royalSlider-preload-image {
  position: absolute;
}
#royalSlider-trimmer .royalSlider-container .rsHor {
  max-height: 400px;
  overflow: hidden;
}
#royalSlider-trimmer .royalSlider-container .rsHor .rsContainer .rsArrowRight {
  right: 0;
}
.royalSlider {
  width: 100%;
  height: 100%;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .royalSlider {
    height: 365px;
  }
}
@media (min-width: 1381px) {
  .royalSlider {
    height: 400px;
  }
}
.royalSlider a.img-as-bg {
  display: block;
  height: 100%;
  width: 100%;
}
.rsDefault,
.rsDefault .rsOverflow,
.rsDefault .rsSlide,
.rsDefault .rsVideoFrameHolder,
.rsDefault .rsThumbs {
  background: transparent;
}
.rsDefault .rsOverflow {
  overflow: hidden;
  max-height: 400px;
}
.rsDefault .rsArrowIcn {
  font-family: FontAwesome;
  color: rgba(0, 0, 0, 0.7);
  font-size: 32px;
  line-height: 32px;
  background: transparent;
  text-align: center;
}
.rsDefault .rsArrowIcn:hover {
  color: rgba(0, 0, 0, 0.9);
  background: transparent;
}
.rsDefault .rsArrow {
  z-index: 96;
  height: 100%;
  width: 44px;
  position: absolute;
  top: 0;
  cursor: pointer;
}
.rsDefault .rsArrowLeft {
  left: 0;
}
.rsDefault .rsArrowLeft .rsArrowIcn:after {
  content: "\f104";
}
.rsDefault .rsArrowRight {
  right: 0;
}
.rsDefault .rsArrowRight .rsArrowIcn:after {
  content: "\f105";
}
.rsDefault .rsArrowIcn {
  width: 32px;
  height: 32px;
  top: 50%;
  margin-top: -16px;
  position: absolute;
}
.rsDefault .rsNav {
  background: transparent;
}
.rsDefault .rsBullets {
  z-index: 97;
  bottom: 30px;
  position: absolute;
  top: 90%;
  width: 100%;
  text-align: center;
  min-height: 17px;
}
.rsDefault .rsBullet {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0 3px;
  display: inline-block;
}
.rsDefault .rsBullet span {
  background-image: url(/assets/images/site/onp_main_sprite.png);
  background-repeat: no-repeat;
  background-position: -240px -50px;
  width: 10px;
  height: 12px;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
  display: block;
}
.rsDefault .rsBullet.rsNavSelected span {
  background-position: -256px -50px;
  background-color: transparent;
}
