@import (reference) '~bootstrap/less/mixins/grid.less';
@import (reference) '~bootstrap/less/mixins/clearfix.less';

@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/mixins.less';
@import (reference) '~resources/less/themes/base/mixins/responsive_utilities.less';

// Wrapper for the Bootstrap Grid System

.clearfix {
    .clearfix();
}

.onp-container (@gutter: @gridGutterWidth, @containerSmWidth: @containerSmWidth, @containerMdWidth: @containerMdWidth, @containerLgWidth: @containerLgWidth) {
    .container-fixed(@gutter);

    @media (min-width: @screenSmMin) {
        width: @containerSmWidth;
    }
    @media (min-width: @screenMdMin) {
        width: @containerMdWidth;
    }
    @media (min-width: @screenLgMin) {
        width: @containerLgWidth;
    }
}

.onp-container-fluid (@gutter: @gridGutterWidth) {
    .container-fixed(@gutter);
}

.onp-row (@gutter: @gridGutterWidth, @table: false) {
    .make-row(@gutter);

    @_table: @table;
    & when not (@_table = false) {
        display: table;
        > * {
            display: table-cell;
            float: none;
        }
    }
}

.onp-column (@xs: false, @sm: false, @md: false, @lg: false, @gutter: @gridGutterWidth) {
    // make arguments visible in a "when" statement
    @_xs: @xs;
    @_sm: @sm;
    @_md: @md;
    @_lg: @lg;

    & when not (@_xs = false) {
        .make-xs-column(@xs, @gutter);
    }
    & when not (@_sm = false) {
        .make-sm-column(@sm, @gutter);
    }
    & when not (@_md = false) {
        .make-md-column(@md, @gutter);
    }
    & when not (@_lg = false) {
        .make-lg-column(@lg, @gutter);
    }
}

.onp-column-offset (@xs: false, @sm: false, @md: false, @lg: false) {
    // make arguments visible in a "when" statement
    @_xs: @xs;
    @_sm: @sm;
    @_md: @md;
    @_lg: @lg;

    & when not (@_xs = false) {
        .make-xs-column-offset(@xs);
    }
    & when not (@_sm = false) {
        .make-sm-column-offset(@sm);
    }
    & when not (@_md = false) {
        .make-md-column-offset(@md);
    }
    & when not (@_lg = false) {
        .make-lg-column-offset(@lg);
    }
}

.onp-column-push (@xs: false, @sm: false, @md: false, @lg: false) {
    // make arguments visible in a "when" statement
    @_xs: @xs;
    @_sm: @sm;
    @_md: @md;
    @_lg: @lg;

    & when not (@_xs = false) {
        .make-xs-column-push(@xs);
    }
    & when not (@_sm = false) {
        .make-sm-column-push(@sm);
    }
    & when not (@_md = false) {
        .make-md-column-push(@md);
    }
    & when not (@_lg = false) {
        .make-lg-column-push(@lg);
    }
}

.onp-column-pull (@xs: false, @sm: false, @md: false, @lg: false) {
    // make arguments visible in a "when" statement
    @_xs: @xs;
    @_sm: @sm;
    @_md: @md;
    @_lg: @lg;

    & when not (@_xs = false) {
        .make-xs-column-pull(@xs);
    }
    & when not (@_sm = false) {
        .make-sm-column-pull(@sm);
    }
    & when not (@_md = false) {
        .make-md-column-pull(@md);
    }
    & when not (@_lg = false) {
        .make-lg-column-pull(@lg);
    }
}

.onp-clear-row (@xs: false, @sm: false, @md: false, @lg: false) {
    & when not (@xs = false) {
        @a: @gridColumns / @xs;
        @styles: {
            &:nth-child(@{a}n + 1) {
                clear: left;
            }
        };
        .responsive-style(xs, @styles);
    }
    & when not (@sm = false) {
        @a: @gridColumns / @sm;
        @styles: {
            &:nth-child(@{a}n + 1) {
                clear: left;
            }
        };
        .responsive-style(sm, @styles);
    }
    & when not (@md = false) {
        @a: @gridColumns / @md;
        @styles: {
            &:nth-child(@{a}n + 1) {
                clear: left;
            }
        };
        .responsive-style(md, @styles);
    }
    & when not (@lg = false) {
        @a: @gridColumns / @lg;
        @styles: {
            &:nth-child(@{a}n + 1) {
                clear: left;
            }
        };
        .responsive-style(lg, @styles);
    }
}
