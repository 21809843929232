@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/mixins.less';
@import '~baseTheme/default/homepage/index.less';
@import '~resources/less/themes/base/elements/simplified/link-container-row.less';

section.main-seo {
    h4 {
        font-size: @baseFontSize;
        line-height: @baseLineHeight;
        text-transform: initial;
        margin: 0;
    }
    h2 {
        font-size: 16px;
        line-height: 24px;
    }
    p {
        font-size: @baseFontSize;
        margin-bottom: 15px;
    }
    a {
        color: @primaryBlue;
        text-decoration: none;
    }
}

.link-container__row {
    a:hover {
        .box-shadow(0 0 @featuredProdShadowWidth 3px @shadowColorLighter)
    }
}
