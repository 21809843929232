@import (reference) '~bootstrap/less/mixins.less';
@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/responsive_utilities.less';

//CSS3 mixins
.border-radius (@radius) {
    -webkit-border-radius: @radius;
       -moz-border-radius: @radius;
            border-radius: @radius;
}

.box-shadow (@shadow) {
    -webkit-box-shadow: @shadow;
       -moz-box-shadow: @shadow;
            box-shadow: @shadow;
}

.user-select (@select) {
    -webkit-user-select: @select;
       -moz-user-select: @select;
        -ms-user-select: @select;
            user-select: @select;
}

.transform (@transformation) {
    -webkit-transform: @transformation; /* Safari */
        transform: @transformation;
}

.transform-origin (@transformation) {
    -webkit-transform-origin: @transformation;
    transform-origin: @transformation;
}

.transition (@transition) {
    -webkit-transition: @transition;
       -moz-transition: @transition;
         -o-transition: @transition;
            transition: @transition;
}

.animation (@animation) {
    -webkit-animation: @animation;
    animation: @animation;
}

.animation-delay (@animation-delay) {
    -webkit-animation-delay: @animation-delay;
    animation-delay: @animation-delay;
}

.keyframes (@name, @block) {
    @-webkit-keyframes @name {
        @block();
    }
    @keyframes @name {
        @block();
    }
}

// Flex model
.flex (@grow: 0, @shrink: 1, @basis: auto) {
    // IE11 doesn't support 'flex' shorthand
    flex-grow: @grow;
    flex-shrink: @shrink;
    flex-basis: @basis;
}

//Gradients
.gradient-vertical (@startColor, @endColor, @plainColor: @startColor) {
    background: @plainColor; //Old browsers
    background: -moz-linear-gradient(top, @startColor 0%, @endColor 100%); //FF3.6+
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @startColor), color-stop(100%, @endColor)); //Chrome,Safari4+
    background: -webkit-linear-gradient(top,  @startColor 0%, @endColor 100%); //Chrome10+,Safari5.1+
    background: -o-linear-gradient(top, @startColor 0%, @endColor 100%); //Opera 11.10+
    background: -ms-linear-gradient(top, @startColor 0%, @endColor 100%); //IE10+
    background: linear-gradient(to bottom, @startColor 0%, @endColor 100%); //W3C
}

.gradient-form-left-to-right (@startColor, @endColor, @plainColor: @startColor) {
    background: @plainColor; //Old browsers
    background: -moz-linear-gradient(right, @startColor 0%, @endColor 100%); //FF3.6+
    background: -webkit-gradient(linear, top left, top right, color-stop(0%, @startColor), color-stop(100%, @endColor)); //Chrome,Safari4+
    background: -webkit-linear-gradient(left,  @startColor 0%, @endColor 100%); //Chrome10+,Safari5.1+
    background: -o-linear-gradient(right, @startColor 0%, @endColor 100%); //Opera 11.10+
    background: -ms-linear-gradient(right, @startColor 0%, @endColor 100%); //IE10+
    background: linear-gradient(to right, @startColor 0%, @endColor 100%); //W3C
}

.gradient-rotated-two-colors (@startColor, @endColor, @angle, @startPoint1, @startPoint2, @startPoint3, @startPoint4) {
    background: @startColor; //Old browsers
    background: -moz-linear-gradient(@angle, @startColor @startPoint1, @startColor @startPoint2, @endColor @startPoint3, @endColor @startPoint4); //FF3.6+
    background: -webkit-gradient(@angle, @startColor @startPoint1, @startColor @startPoint2, @endColor @startPoint3, @endColor @startPoint4); //Chrome,Safari4+
    background: -webkit-linear-gradient(@angle, @startColor @startPoint1, @startColor @startPoint2, @endColor @startPoint3, @endColor @startPoint4); //Chrome10+,Safari5.1+
    background: -o-linear-gradient(@angle, @startColor @startPoint1, @startColor @startPoint2, @endColor @startPoint3, @endColor @startPoint4); //Opera 11.10+
    background: -ms-linear-gradient(@angle, @startColor @startPoint1, @startColor @startPoint2, @endColor @startPoint3, @endColor @startPoint4); //IE10+
    background: linear-gradient(@angle, @startColor @startPoint1, @startColor @startPoint2, @endColor @startPoint3, @endColor @startPoint4);
    -webkit-backface-visibility: hidden;
}

.gradient-radial (@x, @y, @type, @startColor, @startRadius, @endColor, @endRadius) {
    background: -webkit-radial-gradient(@x @y, @type, @startColor @startRadius, @endColor @endRadius);
    background: -moz-radial-gradient(@x @y, @type, @startColor @startRadius, @endColor @endRadius);
    background: -ms-radial-gradient(@x @y, @type, @startColor @startRadius, @endColor @endRadius);
    background: -o-radial-gradient(@x @y, @type, @startColor @startRadius, @endColor @endRadius);
    background: radial-gradient(@x @y, @type, @startColor @startRadius, @endColor @endRadius);
}

.gradient-regular () {
    .gradient-vertical(#f5f5f5, #e7e7e7);
}

.gradient-regular-input () {
    .gradient-vertical(@primaryGrayLighter, #f5f5f7);
}

.gradient-mobile () {
    .gradient-vertical(#fcfcfc, #dedede);
}

.placeholder-styles (@styles) {
    &::-webkit-input-placeholder {
        @styles();
    }
    &::-moz-placeholder {
        @styles();
    }
    &:-ms-input-placeholder {
        @styles();
    }
}

// Buttons
.onp-button () {
    .gradient-vertical(#2cabe2, #1f76bc);
    border: none;
    color: @white;
    text-transform: uppercase;
    font-weight: bold;
}

.onp-button-disabled () {
    .gradient-vertical(#e3e3e3, #c8c8c8);
    border: none;
    color: #999;
}

.small-button () {
    height: 30px;
    padding: 0 7px;
}

.arrow-button () {
    &:after {
        content: "\f04b";
        font-family: FontAwesome;
        font-size: .8em;
        margin-left: .6em;
        vertical-align: bottom;
    }
}

.dropdown-button() {
    border: 1px solid #eee;
    line-height: 26px;
    .border-radius(0);
}

.clearfix () {
    &:before, &:after {
        content: " ";
        display: table;
        line-height: 0;
    }
    &:after {
        clear: both;
    }
}

// Headers
.title-tag () {
    font-family: @headerFontFamily;
    font-weight: normal;
    line-height: 1em;
    position: relative;
    text-transform: uppercase;
}

.title () {
    text-transform: uppercase;
    font-size: @baseFontSize;
    line-height: 14px;
    margin: 0;
}

.accent () {
    font-family: @cursiveHeaderFontFamily;
    font-weight: normal;
    line-height: 1em;
    margin: 0;
    padding: 0;
    position: relative;
}

.gradient-header-block () {
    color: @white;
    padding: 10px 20px;
    .gradient-vertical(@primaryBlueLight, @primaryBlueDark);
}

//Inputs
.input-text () {
    font-size: @baseFontSize;
    line-height: 1em;
    height: auto;
    color: @textColor;
    padding: 9px 10px 5px 10px;
    margin-bottom: 0;
    border-top: 1px solid #c6c6c8;
    border-left: 1px solid #dedee0;
    border-bottom: 1px solid #efeff1;
    .gradient-regular-input();
}

.input-append-text-field () {
    padding-right: 30px !important;
    text-indent: 12px;
    background: #f1f1f1;
    border: none;
    border-bottom: 1px solid #eaeaea;
    color: @textColor;
    height: 36px;
    width: auto;
}

//Filters
.filter (@filters) {
    -webkit-filter: @filters;
    -moz-filter: @filters;
    -o-filter: @filters;
    -ms-filter: @filters;
}

.add-filter (@filters) {
    -webkit-filter+_: @filters;
    -moz-filter+_: @filters;
    -o-filter+_: @filters;
    -ms-filter+_: @filters;
}

//Shadows
.sm-shadow (@moveup: 1) {
    content: "";
    display: block;
    width: 210px;
    height: 10px;
    position: absolute;
    background-position: -320px (-80px - @moveup);
    z-index: 100;
}

.med-shadow (@moveup: 1) {
    content: "";
    display: block;
    width: 300px;
    height: 15px;
    position: absolute;
    bottom: -15px;
    background-position: 0 (-80px - @moveup);
    z-index: 100;
}

//Sprites
.onp-sprite () {
    background-repeat: no-repeat;
    background-color: transparent;
}

.main-sprite () {
    background-image: url(/assets/images/site/onp_main_sprite.png);
    .onp-sprite();
}

.shadow-sprite () {
    background-image: url(/assets/images/site/shadow_sprite.png);
    .onp-sprite();
}

//DVS
.dvs-button () {
    font-size: @baseFontSize;
    line-height: 20px;
    height: 32px;
    border: 1px solid #ddd;
    color: #5a5a5a;
    padding: 5px 2px;
    .gradient-vertical(#f5f5f5, #e8e8e8);
}

.dvs-button-active () {
    background: #8f8f8f;
    border-color: #8f8f8f;
    box-shadow: inset 3px 3px 5px rgba(0,0,0,.08);
    color: #fff;
}

.dvs-select () {
    background-color: @white;
    border: 1px solid #ddd;
    color: #444;
    cursor: pointer;
    height: 32px;
    line-height: 20px;
    margin: 0;
    min-width: 40px;
    padding: 4px 6px;
    width: auto;
}

// Text
.note () {
    color: @primaryBlue;
    line-height: 15px;
}

.centered (@width: 0) {
    .center-block();
    & when (@width > 0) {
        max-width: @width;
    }
    text-align: center;
}

// Other

.absolute-stretch-out () {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.placeholderColor (@color) {
    &::-webkit-input-placeholder {
        color: @color;
    }
    &::-moz-placeholder {
        color: @color;
    }
    &:-moz-placeholder {
        color: @color;
    }
    &:-ms-input-placeholder {
        color: @color;
    }
}

.active-tab () {
    background-color: @tabColorActive;
    &:hover {
        background-color: @tabColorActive;
    }
    a {
        color: @white;
    }
}

// selectBoxIt (apply to selectBoxIt container)
.selectboxit() {
    .selectboxit,
    .selectboxit-options {
        width: 100%;
    }
}

//add to page that has a mobile template to apply min-width for content and not to show mobile header on desktop
//can be merged with base styles when all pages have mobile templates
.min-width-tablet () {
    body {
        #header {
            position: relative;
        }
        .body-content {
            padding-top: 0;
        }
        #header, #body-main, #footer-main {
            min-width: @screenSmMin;
        }
    }
}

.text-stroke (@width, @color) {
    -webkit-text-stroke: @width @color;
    // ie 10+ rules
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        color: @color;
    }
}

.arrow-forward () {
    &:after {
        content: "\f0da";
        font-family: FontAwesome;
        vertical-align: baseline;
        padding-left: 10px;
    }
}

.arrow-back () {
    &:before {
        content: "\f0d9";
        font-family: FontAwesome;
        vertical-align: baseline;
        padding-right: 10px;
    }
}

.shine(@delay) {
    overflow: hidden;
    &:before {
        content: '';
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        transform: translateX(100%);
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(125, 185, 232, 0) 100%
        );
        .animation(slide @delay infinite ease-in-out);
    }

    .keyframes (slide, {
        0% {
            transform: translateX(-100%);
        }
        30% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(100%);
        }
    });
}
