/*
 *  Responsive Utilities
 */
.shopper-approved {
  align-items: center;
  background-color: #3e3e3f;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: "Lato", Tahoma;
  justify-content: flex-start;
  line-height: 22px;
  margin: 10px 0;
  height: 50px;
  padding: 5px 0;
  font-size: 14px;
}
@media (max-width: 767px) {
  .shopper-approved {
    display: none !important;
  }
}
.shopper-approved .shopper-approved-rating-container {
  display: inline-block;
  text-align: center;
  margin: 0 20px;
  line-height: 115%;
}
.shopper-approved .shopper-approved-rating-container i {
  color: #ff9100;
  margin: 0 1px;
  font-size: 11px;
}
.shopper-approved .shopper-approved-rating-count {
  font-size: 150%;
}
.shopper-approved .shopper-approved-rating-title {
  font-size: 100%;
  text-transform: uppercase;
}
.shopper-approved .shopper-approved-payment-methods-container {
  margin: 0 0 0 auto;
  padding: 10px 15px 5px 0;
}
.shopper-approved .shopper-approved-payment-methods-container .shopper-approved-payment-methods-image {
  max-width: 100%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .shopper-approved .shopper-approved-payment-methods-container {
    flex-basis: 300px;
  }
}
