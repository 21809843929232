@import (reference) '~bootstrap/less/mixins/responsive-visibility.less';
@import (reference) '~resources/less/themes/base/variables.less';

/*
 *  Responsive Utilities
 */

// Visibility management mixins:

.onp-hidden (@xs: false, @sm: false, @md: false, @lg: false, @small: false, @big: false, @non-xs: false, @print: false) {
    // make arguments visible in a "when" statement
    @_xs: @xs;
    @_sm: @sm;
    @_md: @md;
    @_lg: @lg;
    @_small: @small;
    @_big: @big;
    @_non-xs: @non-xs;
    @_print: @print;

    @styles: {
        .responsive-invisibility();
    };

    & when (@_small = true) {
        .responsive-style(xs, @styles);
        .responsive-style(sm, @styles);
    }
    & when (@_big = true) {
        .responsive-style(md, @styles);
        .responsive-style(lg, @styles);
    }
    & when (@_non-xs = true) {
        .responsive-style(sm, @styles);
        .responsive-style(md, @styles);
        .responsive-style(lg, @styles);
    }
    & when (@_xs = true) {
        .responsive-style(xs, @styles);
    }
    & when (@_sm = true) {
        .responsive-style(sm, @styles);
    }
    & when (@_md = true) {
        .responsive-style(md, @styles);
    }
    & when (@_lg = true) {
        .responsive-style(lg, @styles);
    }
    & when (@_print = true) {
        .responsive-style(print, @styles);
    }
}

.onp-visible (@xs: false, @sm: false, @md: false, @lg: false, @small: false, @big: false, @non-xs: false, @print: false) {
    .responsive-invisibility();

    // make arguments visible in a "when" statement
    @_xs: @xs;
    @_sm: @sm;
    @_md: @md;
    @_lg: @lg;
    @_small: @small;
    @_big: @big;
    @_non-xs: @non-xs;
    @_print: @print;

    & when not (@_small = false) {
        .set-display(xs, @small);
        .set-display(sm, @small);
    }
    & when not (@_big = false) {
        .set-display(md, @big);
        .set-display(lg, @big);
    }
    & when not (@_non-xs = false) {
        .set-display(sm, @non-xs);
        .set-display(md, @non-xs);
        .set-display(lg, @non-xs);
    }
    & when not (@_xs = false) {
        .set-display(xs, @xs);
    }
    & when not (@_sm = false) {
        .set-display(sm, @sm);
    }
    & when not (@_md = false) {
        .set-display(md, @md);
    }
    & when not (@_lg = false) {
        .set-display(lg, @lg);
    }
    & when not (@_print = false) {
        .set-display(print, @print);
    }
}

.onp-style (@xs: false, @sm: false, @md: false, @lg: false, @small: false, @big: false, @non-xs: false, @print: false) {
    // make arguments visible in a "when" statement
    @_xs: @xs;
    @_sm: @sm;
    @_md: @md;
    @_lg: @lg;
    @_small: @small;
    @_big: @big;
    @_non-xs: @non-xs;
    @_print: @print;

    & when not (@_small = false) {
        .responsive-style(xs, @small);
        .responsive-style(sm, @small);
    }
    & when not (@_big = false) {
        .responsive-style(md, @big);
        .responsive-style(lg, @big);
    }
    & when not (@_non-xs = false) {
        .responsive-style(sm, @non-xs);
        .responsive-style(md, @non-xs);
        .responsive-style(lg, @non-xs);
    }
    & when not (@_xs = false) {
        .responsive-style(xs, @xs);
    }
    & when not (@_sm = false) {
        .responsive-style(sm, @sm);
    }
    & when not (@_md = false) {
        .responsive-style(md, @md);
    }
    & when not (@_lg = false) {
        .responsive-style(lg, @lg);
    }
    & when not (@_print = false) {
        .responsive-style(print, @print);
    }
}

// Accessory Mixins:

.set-display (@for, @display) when (@display = true) {
    @styles: {
        .responsive-visibility();
    };
    .responsive-style(@for, @styles);
}

.set-display (@for, @display) when (default()) {
    @styles: {
        display: @display !important;
    };
    .responsive-style(@for, @styles);
}

.responsive-style (@for, @styles) {
    @_for: @for;

    & when (@_for = lg) {
        @media (min-width: @screen-lg-min) {
            @styles();
        }
    }
    & when (@_for = md) {
        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
            @styles();
        }
    }
    & when (@_for = sm) {
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            @styles();
        }
    }
    & when (@_for = xs) {
        @media (max-width: @screen-xs-max) {
            @styles();
        }
    }

    // other media
    & when (@_for = print) {
        @media print {
            @styles();
        }
    }
}

.orientation (@orientation, @styles) {
    @media (orientation: @orientation) {
        @styles();
    }
}
