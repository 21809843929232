.live-assist(@type, @padding: '20px 30px !important') {
    .LPMcontainer {
        cursor: pointer !important;
        display: flex !important;
        height: 100%;
        margin: 0 !important;
        outline: none;
        padding: ~'@{padding}';
        position: absolute !important;
        width: 100%;
        .@{type}-live-assist {
            width: 100%;
            height: 100%;
        }
    }
}
