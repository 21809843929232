@media (min-width: 768px) and (max-width: 1023px) {
  .dialog-padding,
  .dialog-popup-header-title,
  .dialog-popup-body,
  .dialog-popup-footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .dialog-padding,
  .dialog-popup-header-title,
  .dialog-popup-body,
  .dialog-popup-footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (min-width: 1381px) {
  .dialog-padding,
  .dialog-popup-header-title,
  .dialog-popup-body,
  .dialog-popup-footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .dialog-padding,
  .dialog-popup-header-title,
  .dialog-popup-body,
  .dialog-popup-footer {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.dialog-popup-mask {
  padding: 10px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .3s ease;
}
.dialog-popup-container {
  max-width: 95%;
  padding: 3px;
  background-color: #fff;
  border-radius: 2px;
  transition: all .3s ease;
  font: 14px "Lato", Tahoma;
  box-shadow: 0 0 8px rgba(68, 68, 68, 0.6);
  max-height: 100%;
  overflow: auto;
}
.dialog-popup-toolbar {
  z-index: 101;
  position: absolute;
  right: 8px;
  top: 0;
}
.dialog-popup-toolbar-close {
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url("/assets/images/site/onp_main_sprite.png") -292px -26px no-repeat;
}
.dialog-popup-header {
  position: relative;
  margin-top: 10px;
}
.dialog-popup-header-title {
  padding-right: 30px;
  margin: 0 0 10px;
  font-family: "Lato", Tahoma;
  font-weight: normal;
  line-height: 1em;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
}
.dialog-popup-body {
  min-width: 100px;
  max-width: 100%;
  padding-bottom: 30px;
  overflow: auto;
}
.dialog-popup-footer {
  padding-bottom: 30px;
}
