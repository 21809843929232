@import (reference) '../../variables.less';

.link-container__row {
    padding: 10px 0;

    a {
        display: block;
        border: 1px solid @borderColor;
        background-color: @primaryGrayLighter;
        text-decoration: none;
        color: @primaryGray;
        font-size: 18pt;
        font-weight: bold;
        padding: 11px;
        text-align: center;
    }

    a:hover {
        color: @primaryGrayDarker;
    }
}