@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~bootstrap/less/bootstrap.less';
@import (reference) '~baseTheme/_common/base/mixins.less';
@import (reference) '~resources/less/themes/base/mixins/mixins.less';
@import (reference) '~resources/less/themes/base/mixins/responsive_utilities.less';
@import (reference) '~baseTheme/_common/live_assist/index.less';

@import '~resources/less/themes/base/elements/simplified/button_secondary.less';

@headerButtonsHeight: 36px;

#header {
    display: flex;
    justify-content: space-between;
    height: 88px;
    background-color: @primaryBlueDarkest;
    font-size: @baseFontSize;
    z-index: @menuZIndex + 2;
    position: relative;

    .main {
        display: flex;
        flex-shrink: 0;
        margin-left: 30px;
        .logo {
            a,
            img {
                max-width: 100%;
                display: block;
            }
        }
        .menu {
            display: flex;
            align-items: flex-end;
            z-index: @menuZIndex;
        }
        .main-menu {
            list-style-type: none;
            display: flex;
            padding: 0;
            margin: 0;
        }
    }
    .menu-item {
        padding: 0 5px;
        a, span {
            z-index: auto;
            display: block;
            height: 100%;
            padding: 7px 5px;
            border-radius: 3px 3px 0 0;
            color: @white;
            font-family: @latoFontFamily;
            cursor: pointer;
            text-decoration: none;
            white-space: nowrap;
            .icon-caret-down,
            .icon-country {
                margin: 0 3px;
            }
        }
        > a > span {
            padding: 0;
            cursor: pointer;
        }
        span {
            cursor: default;
        }
        &:hover {
            a, span {
                .active-menu-item()
            }
        }
    }
    .menu-item-countries,
    .menu-item-bc {
        position: relative;
        &:hover {
            ul {
                visibility: visible;
                opacity: 1;
            }
        }
        ul {
            visibility: hidden;
            opacity: 0;
            list-style-type: none;
            background: @white;
            width: 70px;
            position: absolute;
            border: 1px solid @primaryBlueDarkest;
            border-top: 0;
            right: 4px;
            padding: 0 6px;
            border-radius: 0 0 5px 5px;
            transition-property: opacity, visibility;
            transition-duration: .2s, .2s;
            transition-delay: 0s, .1ms;
            li {
                display: block;
                a {
                    color: @black;
                    padding: 3px 5px;
                    .link-transition();
                    &:hover {
                        color: @primaryBlue;
                    }
                }
            }
        }
    }
    .menu-item-countries {
        .icon-country {
            width: 16px;
            height: 16px;
            float: left;
            position: relative;
        }
        .countries-icons('16');
    }
    .menu-item-bc {
        ul {
            width: auto;
            min-width: 215px;
            padding: 10px 6px;
            left: 4px;

            li a {
                padding: 5px 15px;
            }
        }
    }
    .settings {
        color: @white;
        font-family: @latoFontFamily;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 30px 0 10px;
        flex-shrink: 0;
        z-index: @menuZIndex - 1;
        // hack for ipad
        height: 100%;
        position: absolute;
        right: 0;
        .account-and-cart {
            display: flex;
            .account {
                .user-name {
                    color: @primaryYellow;
                    margin-bottom: 4px;
                }
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin-right: 20px;
                text-align: right;
                .separator {
                    margin: 0 4px;
                }
                a {
                    color: @white;
                    text-transform: capitalize;
                }
                a,
                span:not(.separator) {
                    .link-transition();
                    &:hover {
                        color: @primaryYellow;
                    }
                }
                .user-account-links {
                    position: relative;
                    a {
                        text-decoration: none;
                    }
                }
            }
            .cart {
                position: relative;
                border-radius: 3px;
                height: @headerButtonsHeight;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                color: @white;
                .icon-shopping-cart {
                    padding-right: 7px;
                }
                .cart-items-count {
                    display: none;
                    width: 16px;
                    padding: 2px;
                    position: absolute;
                    left: 14px;
                    top: -1px;
                    text-align: center;
                    font: 10px @headerFontFamily;
                    background-color: @primaryRed;
                    border-radius: 50%;
                }
            }
        }
    }

    &.active-menu-1 .dropdown-menu-item-1,
    &.active-menu-5 .dropdown-menu-item-5,
    &.active-menu-6 .dropdown-menu-item-6,
    &.active-menu-7 .dropdown-menu-item-7,
    &.active-menu-8 .dropdown-menu-item-8 {
        a,
        span {
            .active-menu-item()
        }
    }

    ~ .menu-item-content {
        max-height: 400px;
        top: -450px;
        position: absolute;
        background: #fff;
        width: 100%;
        left: 0;
        color: #000;
        transition: top .2s linear;
        z-index: @menuZIndex + 1;
        .onp-style(
            @big: {
                display: block;
            },
            @small: {
                display: none;
            }
        );
        .title,
        .title a {
            color: @primaryBlue !important;
            font-weight: 700 !important;
            font-size: 15px;
            padding: 10px 0;
            text-decoration: none;
        }
        .content {
            display: flex;
            justify-content: center;
            padding: 16px;
            .column {
                border-left: 1px solid @primaryBlueLight;
                padding: 2px 30px 20px;
                position: relative;
                &:first-of-type {
                    border-left: none;
                }
                &.align-center {
                    text-align: center;
                }
                ul {
                    list-style-type: none;
                    padding: 0;
                }
                li {
                    padding: 5px 0;
                }
                a {
                    color: @grayLight;
                    font-size: 15px;
                    font-weight: 500;
                    text-decoration: none;
                    .link-transition();
                    &:hover {
                        color: @primaryBlue;
                    }
                }
            }
            .column-2 {
                ul {
                    column-gap: 30px;
                    column-count: 2;
                }
            }
        }
        .footer {
            background: @primaryBlueLight;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .item {
                margin: 0 60px;
                height: 28px;

                a {
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    color: @primaryBlueDarkest;
                    margin-left: 34px;
                    text-decoration: none;
                }

                svg {
                    fill: @primaryBlueDarkest;
                    height: 28px;
                    width: 28px;
                    margin-right: 6px;
                }

                &:hover {
                    svg {
                        fill: @white;
                    }
                    a {
                        color: @white;
                    }
                }
            }
        }
        * {
            font-family: @latoFontFamily;
        }
    }
    ~ .menu-item-content-7 {
        .title {
            padding-top: 18px;
            text-align: center;
        }
        .content {
            .column {
                border: none;
                text-align: center;
                img {
                    display: block;
                    padding-bottom: 20px;
                }
            }
        }
    }
    ~ .menu-item-content-8 {
        .content {
            .column {
                vertical-align: top;
                padding-top: 20px;
                &.faq {
                    width: 170px;
                }
                &.designs {
                    width: 160px;
                }
                img {
                    width: 30px;
                    height: 30px;
                    margin-left: 10px;
                    flex-shrink: 0;
                }
                a {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    &:hover {
                        color: @grayLight;
                    }
                    .title {
                        font-weight: 700;
                        display: flex;
                        align-items: flex-start;
                        color: @primaryBlueDarkest;
                        width: 100%;
                        justify-content: center;
                    }
                    .text {
                        margin-top: 5px;
                    }
                    .desc {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 10px;
                        text-align: center;
                        .blue {
                            color: @primaryBlue;
                        }
                    }
                }
            }
        }
        .live-assist(desktop-menu);
    }

    ~ .body-overlay {
        position: fixed;
        z-index: @menuZIndex;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.2s linear;
        pointer-events: none;
        .onp-style(
            @big: {
                display: block;
            },
            @small: {
                display: none;
            }
        );
    }
    &.hovered ~ .body-overlay {
        opacity: .5;
        pointer-events: auto;
    }

    .onp-style(
        @lg: {
            .main {
                flex-flow: row;
                justify-content: flex-start;
                .logo {
                    margin-right: 30px;
                    align-items: center;
                    display: flex;
                    width: 380px;
                }
            }
        },
        @big: {
            display: flex;
        },
        @small: {
            display: none;
        }
    );

    @media (max-width: @mainMenuWrapThreshold) {
        height: 115px;
        .main {
            flex-flow: column;
            justify-content: space-between;
            .logo {
                margin-top: 20px;
                width: 325px;
            }
        }
        .menu-item {
            a, span {
                padding: 10px 5px;
            }
        }

        .settings {
            justify-content: flex-start;
            padding-top: 30px;
        }
    }
}

#mobile-header {
    .onp-style(
        @big: {
            display: none;
        },
        @small: {
            display: flex;
        }
    );
    height: @mobileHeaderHeight;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    justify-content: space-between;
    background-color: @primaryBlueDarkest;
    padding: 10px 15px;
    align-items: center;
    z-index: @menuZIndex;
    font-size: @baseFontSize;
    .menu-icon {
        color: @white;
        flex-basis: 25%;
    }
    .logo {
        flex-basis: 50%;
        text-align: center;
        img {
            display: block;
            margin: 0 auto;
            max-height: @mobileHeaderHeight;
            max-width: 100%;
        }
    }
    .cart {
        position: relative;
        color: @white;
        flex-basis: 25%;
        display: flex;
        justify-content: flex-end;
        text-decoration: none;
        .cart-items-count {
            display: none;
            width: 16px;
            padding: 2px;
            position: absolute;
            right: 2px;
            top: -3px;
            text-align: center;
            font: 10px @headerFontFamily;
            background-color: @primaryRed;
            border-radius: 50%;
        }
    }
}

.mobile-menu {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: @menuZIndex + 3;
    transition: left .2s linear;
    .menu-item-content-from-top(@headerHeight) {
        height: calc(~'100% - @{headerHeight}');
        top: @headerHeight;
    }
    &.opened {
        left: 0;
        display: flex;
        flex-direction: column;
        ~ #body-main {
            display: none;
        }
    }
    .logo {
        padding: 10px 10px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: @primaryBlueDarkest;
        a,
        img {
            width: 250px;
            display: block;
        }
        .icon-close {
            color: @white;
            // font family for unicode characters 2700-27BF
            // otherwise changing color doesn't work in safari
            font: 28px 'Zapf Dingbats';
        }
    }
    .account {
        padding: 10px;
        background-color: @primaryBlueDarkest;
        .user-name {
            display: inline-block;
            position: relative;
            color: @primaryYellow;
            font-family: @latoFontFamily;
            margin-bottom: 6px;
        }
        .user-account-links {
            font-size: 16px;
            font-family: @latoFontFamily;
            color: @white;
            a {
                display: inline-block;
                margin: 2px 5px;
                color: @white;
                text-decoration: none;
                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }
    > .menu-content {
        height: 100%;
        background: @white;
        overflow-y: scroll;
        ul {
            padding: 10px 0;
            li {
                padding: 20px 30px;
                position: relative;
                &.border-bottom {
                    border-bottom: 1px solid @grayLight;
                }
                &.country {
                    padding: 10px 30px 14px;
                    border-top: 1px solid @grayLight;
                    a {
                        display: flex;
                        align-items: center;
                    }
                }
                a {
                    color: @primaryBlueDarkest;
                    font: 600 16px @latoFontFamily;
                    display: block;
                    text-decoration: none;
                    .icon-angle-right {
                        position: absolute;
                        right: 30px;
                        top: 50%;
                        margin-top: -8px;
                    }

                }
            }
        }
    }
    > .menu-item-content {
        .menu-item-content-from-top(86px);
        width: 100%;
        right: -200%;
        position: absolute;
        background: @white;
        transition: right 0.2s linear;
        overflow-y: scroll;
        > .title {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            color: @white;
            background: @primaryBlueDark;
            position: fixed;
            width: 100%;
            z-index: 1;
            a {
                font-family: @latoFontFamily;
                color: @white;
                text-decoration: none;
                i {
                    margin-right: 5px;
                }
            }
            span {
                font-family: @latoFontFamily;
                font-weight: 600;
            }
        }
        ul {
            margin-top: 36px;
            list-style-type: none;
            padding: 0;
            li {
                padding: 20px 30px;
                position: relative;
                &.title {
                    background: @primaryBlueLight;
                    padding: 20px;
                    color: @primaryBlueDarkest;
                    font-size: 16px;
                    font-weight: 600;
                    border: 1px solid @grayLight;
                    border-left: 0;
                    border-right: 0;
                }
                a {
                    color: @primaryBlueDarkest;
                    font: 600 16px @latoFontFamily;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    .countries-icons('32');
                }
            }
        }
    }
    &.logged-in > .menu-item-content {
        .menu-item-content-from-top(110px);
    }
    &.active-menu-1 > .menu-item-content-1,
    &.active-menu-5 > .menu-item-content-5,
    &.active-menu-6 > .menu-item-content-6,
    &.active-menu-7 > .menu-item-content-7,
    &.active-menu-8 > .menu-item-content-8,
    &.active-menu-11 > .menu-item-content-11 {
        right: 0;
    }
    > .menu-content,
    > .menu-item-content-11 {
        .icon-country {
            width: 32px;
            height: 32px;
            display: inline-block;
            margin-right: 10px;
        }
        .countries-icons('32');
    }
    > .menu-item-content-8 {
        ul {
            li {
                &.border-bottom {
                    border-bottom: 1px solid @grayLight;
                }
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .desc {
                        span {
                            display: block;
                        }
                        .blue {
                            color: @primaryBlue;
                        }
                    }
                    .icon {
                        flex-basis: 30px;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
    > .menu-item-content-11 {
        ul {
            li {
                padding: 10px 30px 14px;
            }
        }
    }
    .live-assist(mobile-menu);
}

#status-header {
    display: none;
    position: relative;
    z-index: @menuZIndex + 2;
    &.alert {
        .onp-style(
            @big: {
                display: block;
            }
        );
    }
    padding: 2px;
    width: 100%;
    margin-bottom: 0;
}

.vip-level-star {
    top: -8px;
    left: -35px;
    width: 30px;
    height: 30px;
    position: absolute;
    display: block;

    .onp-style(
        @xs: {
            display: none;
        }
    );

    &.mobile {
        display: none;
        margin-left: 10px;
        .onp-style(
            @xs: {
                display: block;
                left: auto;
                right: -40px;
            }
        );
    }

    &.animate {
        .animation(blink 1s);
    }

    .keyframes (blink, {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.15);
        }
        100% {
            transform: scale(1);
        }
    });

    &.level-bronze {
        background-image: url("/assets/images/vip/Star_Bronze.png");
    }

    &.level-silver {
        background-image: url("/assets/images/vip/Star_Silver.png");
    }

    &.level-gold {
        background-image: url("/assets/images/vip/Star_Gold.png");
    }

    &.level-platinum {
        background-image: url("/assets/images/vip/Star_Platinum.png");
    }
}

.vip-level-bar {
    width: 100%;
    height: 15px;
    position: relative;

    .shine(10s);

    &.level-bronze {
        background-image: url("/assets/images/vip/Line_Bronze-min.png");
    }

    &.level-silver {
        background-image: url("/assets/images/vip/Line_Silver-min.png");
    }

    &.level-gold {
        background-image: url("/assets/images/vip/Line_Gold-min.png");
    }

    &.level-platinum {
        background-image: url("/assets/images/vip/Line_Platinum-min.png");
    }
}
