@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/grid.less';
@import '~normalize.css/normalize.css';

html {
    box-sizing: border-box;
}

// for google optimize anti-flicker
.async-hide {
    opacity: 0 !important;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    font-size: @baseFontSize;
    font-family: @latoFontFamily;
}

.hidden {
    display: none;
}

.body-content {
    margin: 0 auto;
    .onp-style(
        @small: {
            margin-top: 49px;
        },
        @lg: {
            width: @containerFlexLgWidth;
        },
        @md: {
            width: @containerFlexMdWidth;
        },
        @sm: {
            width: @containerFlexSmWidth;
        },
        @xs: {
            width: @containerFlexXsWidth;
        }
    );
}
