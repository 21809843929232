@import (reference) '~resources/less/themes/base/variables.less';

.btn-secondary {
    border: none;
    color: @white;
    cursor: pointer;
    line-height: 1em;
    padding: 6px 12px;
    text-decoration: none;
    transition: background 0.3s ease;
    &,
    &:focus {
        background: @secondaryButtonColor;
    }

    &:hover {
        background: @secondaryButtonHover;
    }

    &:active {
        background: @secondaryButtonActive;
    }
}
