@import '~baseTheme/_common/base/header/index.less';

.special-offer {
    display: flex;
    justify-content: space-between;
    color: @primaryBlue;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;

    .special-offer-code {
        color: @primaryGray;
    }

    .special-offer-link {
        width: 20px;
        border-radius: 10px;
        display: inline-block;
        text-align: center;
        color: @white;
        background-color: @primaryBlue;
        font-family: FontAwesome;

        &:after {
            content: "\f0da";
        }

        &:hover {
            color: @white;
        }
    }
}

.special-offer-valid {
    font-size: @baseFontSize;
    text-align: center;
    color: @primaryGray;
}

.special-offer-desc {
    font-size: @baseFontSize;
    text-align: center;
    color: @primaryGray;
    width: 166px;
}
