#vip-popup {
  text-align: center;
}
#vip-popup .vip-popup-star {
  margin-top: -60px;
}
#vip-popup.mobile .vip-popup-star {
  margin-top: 0;
}
#vip-popup h3 {
  font-size: 17.5px;
}
#vip-popup p {
  margin-bottom: 30px;
}
#vip-popup .dialog-popup-container,
#vip-popup .dialog-popup-body {
  overflow: visible;
}
#vip-popup .dialog-popup-toolbar-close {
  margin-top: -22px;
}
#vip-popup .dialog-popup-container {
  position: absolute;
  transition: transform 1s;
  transform-origin: 50% 50%;
}
