html {
  box-sizing: border-box;
}
.async-hide {
  opacity: 0 !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-size: 14px;
  font-family: "Lato", Tahoma;
}
.hidden {
  display: none;
}
.body-content {
  margin: 0 auto;
}
@media (max-width: 767px) {
  .body-content {
    margin-top: 49px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .body-content {
    margin-top: 49px;
  }
}
@media (max-width: 767px) {
  .body-content {
    width: 92%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .body-content {
    width: 711px;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .body-content {
    width: 967px;
  }
}
@media (min-width: 1381px) {
  .body-content {
    width: 1324px;
  }
}

@media screen and (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (max-width: 767px) {
}.btn-secondary {
  border: none;
  color: #fff;
  cursor: pointer;
  line-height: 1em;
  padding: 6px 12px;
  text-decoration: none;
  transition: background 0.3s ease;
}
.btn-secondary,
.btn-secondary:focus {
  background: #01aee3;
}
.btn-secondary:hover {
  background: #01bbf4;
}
.btn-secondary:active {
  background: #0386dd;
}
#header {
  display: flex;
  justify-content: space-between;
  height: 88px;
  background-color: #061c38;
  font-size: 14px;
  z-index: 100;
  position: relative;
}
#header .main {
  display: flex;
  flex-shrink: 0;
  margin-left: 30px;
}
#header .main .logo a,
#header .main .logo img {
  max-width: 100%;
  display: block;
}
#header .main .menu {
  display: flex;
  align-items: flex-end;
  z-index: 98;
}
#header .main .main-menu {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}
#header .menu-item {
  padding: 0 5px;
}
#header .menu-item a,
#header .menu-item span {
  z-index: auto;
  display: block;
  height: 100%;
  padding: 7px 5px;
  border-radius: 3px 3px 0 0;
  color: #fff;
  font-family: "Lato", Tahoma;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
}
#header .menu-item a .icon-caret-down,
#header .menu-item span .icon-caret-down,
#header .menu-item a .icon-country,
#header .menu-item span .icon-country {
  margin: 0 3px;
}
#header .menu-item > a > span {
  padding: 0;
  cursor: pointer;
}
#header .menu-item span {
  cursor: default;
}
#header .menu-item:hover a,
#header .menu-item:hover span {
  background: #fff;
  color: #737375;
}
#header .menu-item-countries,
#header .menu-item-bc {
  position: relative;
}
#header .menu-item-countries:hover ul,
#header .menu-item-bc:hover ul {
  visibility: visible;
  opacity: 1;
}
#header .menu-item-countries ul,
#header .menu-item-bc ul {
  visibility: hidden;
  opacity: 0;
  list-style-type: none;
  background: #fff;
  width: 70px;
  position: absolute;
  border: 1px solid #061c38;
  border-top: 0;
  right: 4px;
  padding: 0 6px;
  border-radius: 0 0 5px 5px;
  transition-property: opacity, visibility;
  transition-duration: .2s, .2s;
  transition-delay: 0s, .1ms;
}
#header .menu-item-countries ul li,
#header .menu-item-bc ul li {
  display: block;
}
#header .menu-item-countries ul li a,
#header .menu-item-bc ul li a {
  color: #000;
  padding: 3px 5px;
  transition: color .15s linear;
}
#header .menu-item-countries ul li a:hover,
#header .menu-item-bc ul li a:hover {
  color: #01aee3;
}
#header .menu-item-countries .icon-country {
  width: 16px;
  height: 16px;
  float: left;
  position: relative;
}
#header .menu-item-countries .icon-country-us {
  background: url('/assets/compiled/images/flags/16/United-States.png');
}
#header .menu-item-countries .icon-country-at {
  background: url('/assets/compiled/images/flags/16/Austria.png');
}
#header .menu-item-countries .icon-country-de_AT {
  background: url('/assets/compiled/images/flags/16/Germany.png');
}
#header .menu-item-countries .icon-country-fr {
  background: url('/assets/compiled/images/flags/16/France.png');
}
#header .menu-item-countries .icon-country-de {
  background: url('/assets/compiled/images/flags/16/Germany.png');
}
#header .menu-item-countries .icon-country-uk {
  background: url('/assets/compiled/images/flags/16/United-Kingdom.png');
}
#header .menu-item-countries .icon-country-de_BE {
  background: url('/assets/compiled/images/flags/16/Germany.png');
}
#header .menu-item-countries .icon-country-be {
  background: url('/assets/compiled/images/flags/16/Belgium.png');
}
#header .menu-item-countries .icon-country-es {
  background: url('/assets/compiled/images/flags/16/Spain.png');
}
#header .menu-item-countries .icon-country-eu {
  background: url('/assets/compiled/images/flags/16/European-Union.png');
}
#header .menu-item-countries .icon-country-de_LU {
  background: url('/assets/compiled/images/flags/16/Germany.png');
}
#header .menu-item-countries .icon-country-lu {
  background: url('/assets/compiled/images/flags/16/Luxembourg.png');
}
#header .menu-item-countries .icon-country-nl {
  background: url('/assets/compiled/images/flags/16/Netherlands.png');
}
#header .menu-item-countries .icon-country-it {
  background: url('/assets/compiled/images/flags/16/Italy.png');
}
#header .menu-item-countries .icon-country-de_CH {
  background: url('/assets/compiled/images/flags/16/Germany.png');
}
#header .menu-item-countries .icon-country-ch {
  background: url('/assets/compiled/images/flags/16/Switzerland.png');
}
#header .menu-item-countries .icon-country-cz {
  background: url('/assets/compiled/images/flags/16/Czech-Republic.png');
}
#header .menu-item-countries .icon-country-hi {
  background: url('/assets/compiled/images/flags/16/India.png');
}
#header .menu-item-countries .icon-country-ta {
  background: url('/assets/compiled/images/flags/16/India.png');
}
#header .menu-item-countries .icon-country-pl {
  background: url('/assets/compiled/images/flags/16/Poland.png');
}
#header .menu-item-countries .icon-country-ru {
  background: url('/assets/compiled/images/flags/16/Russia.png');
}
#header .menu-item-bc ul {
  width: auto;
  min-width: 215px;
  padding: 10px 6px;
  left: 4px;
}
#header .menu-item-bc ul li a {
  padding: 5px 15px;
}
#header .settings {
  color: #fff;
  font-family: "Lato", Tahoma;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 30px 0 10px;
  flex-shrink: 0;
  z-index: 97;
  height: 100%;
  position: absolute;
  right: 0;
}
#header .settings .account-and-cart {
  display: flex;
}
#header .settings .account-and-cart .account {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 20px;
  text-align: right;
}
#header .settings .account-and-cart .account .user-name {
  color: #ffc107;
  margin-bottom: 4px;
}
#header .settings .account-and-cart .account .separator {
  margin: 0 4px;
}
#header .settings .account-and-cart .account a {
  color: #fff;
  text-transform: capitalize;
}
#header .settings .account-and-cart .account a,
#header .settings .account-and-cart .account span:not(.separator) {
  transition: color .15s linear;
}
#header .settings .account-and-cart .account a:hover,
#header .settings .account-and-cart .account span:not(.separator):hover {
  color: #ffc107;
}
#header .settings .account-and-cart .account .user-account-links {
  position: relative;
}
#header .settings .account-and-cart .account .user-account-links a {
  text-decoration: none;
}
#header .settings .account-and-cart .cart {
  position: relative;
  border-radius: 3px;
  height: 36px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #fff;
}
#header .settings .account-and-cart .cart .icon-shopping-cart {
  padding-right: 7px;
}
#header .settings .account-and-cart .cart .cart-items-count {
  display: none;
  width: 16px;
  padding: 2px;
  position: absolute;
  left: 14px;
  top: -1px;
  text-align: center;
  font: 10px "Lato", Tahoma;
  background-color: #c70607;
  border-radius: 50%;
}
#header.active-menu-1 .dropdown-menu-item-1 a,
#header.active-menu-5 .dropdown-menu-item-5 a,
#header.active-menu-6 .dropdown-menu-item-6 a,
#header.active-menu-7 .dropdown-menu-item-7 a,
#header.active-menu-8 .dropdown-menu-item-8 a,
#header.active-menu-1 .dropdown-menu-item-1 span,
#header.active-menu-5 .dropdown-menu-item-5 span,
#header.active-menu-6 .dropdown-menu-item-6 span,
#header.active-menu-7 .dropdown-menu-item-7 span,
#header.active-menu-8 .dropdown-menu-item-8 span {
  background: #fff;
  color: #737375;
}
#header ~ .menu-item-content {
  max-height: 400px;
  top: -450px;
  position: absolute;
  background: #fff;
  width: 100%;
  left: 0;
  color: #000;
  transition: top .2s linear;
  z-index: 99;
}
@media (max-width: 767px) {
  #header ~ .menu-item-content {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #header ~ .menu-item-content {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  #header ~ .menu-item-content {
    display: block;
  }
}
@media (min-width: 1381px) {
  #header ~ .menu-item-content {
    display: block;
  }
}
#header ~ .menu-item-content .title,
#header ~ .menu-item-content .title a {
  color: #01aee3 !important;
  font-weight: 700 !important;
  font-size: 15px;
  padding: 10px 0;
  text-decoration: none;
}
#header ~ .menu-item-content .content {
  display: flex;
  justify-content: center;
  padding: 16px;
}
#header ~ .menu-item-content .content .column {
  border-left: 1px solid #81CEEC;
  padding: 2px 30px 20px;
  position: relative;
}
#header ~ .menu-item-content .content .column:first-of-type {
  border-left: none;
}
#header ~ .menu-item-content .content .column.align-center {
  text-align: center;
}
#header ~ .menu-item-content .content .column ul {
  list-style-type: none;
  padding: 0;
}
#header ~ .menu-item-content .content .column li {
  padding: 5px 0;
}
#header ~ .menu-item-content .content .column a {
  color: #777777;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  transition: color .15s linear;
}
#header ~ .menu-item-content .content .column a:hover {
  color: #01aee3;
}
#header ~ .menu-item-content .content .column-2 ul {
  column-gap: 30px;
  column-count: 2;
}
#header ~ .menu-item-content .footer {
  background: #81CEEC;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#header ~ .menu-item-content .footer .item {
  margin: 0 60px;
  height: 28px;
}
#header ~ .menu-item-content .footer .item a {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #061c38;
  margin-left: 34px;
  text-decoration: none;
}
#header ~ .menu-item-content .footer .item svg {
  fill: #061c38;
  height: 28px;
  width: 28px;
  margin-right: 6px;
}
#header ~ .menu-item-content .footer .item:hover svg {
  fill: #fff;
}
#header ~ .menu-item-content .footer .item:hover a {
  color: #fff;
}
#header ~ .menu-item-content * {
  font-family: "Lato", Tahoma;
}
#header ~ .menu-item-content-7 .title {
  padding-top: 18px;
  text-align: center;
}
#header ~ .menu-item-content-7 .content .column {
  border: none;
  text-align: center;
}
#header ~ .menu-item-content-7 .content .column img {
  display: block;
  padding-bottom: 20px;
}
#header ~ .menu-item-content-8 .content .column {
  vertical-align: top;
  padding-top: 20px;
}
#header ~ .menu-item-content-8 .content .column.faq {
  width: 170px;
}
#header ~ .menu-item-content-8 .content .column.designs {
  width: 160px;
}
#header ~ .menu-item-content-8 .content .column img {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  flex-shrink: 0;
}
#header ~ .menu-item-content-8 .content .column a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#header ~ .menu-item-content-8 .content .column a:hover {
  color: #777777;
}
#header ~ .menu-item-content-8 .content .column a .title {
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  color: #061c38;
  width: 100%;
  justify-content: center;
}
#header ~ .menu-item-content-8 .content .column a .text {
  margin-top: 5px;
}
#header ~ .menu-item-content-8 .content .column a .desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  text-align: center;
}
#header ~ .menu-item-content-8 .content .column a .desc .blue {
  color: #01aee3;
}
#header ~ .menu-item-content-8 .LPMcontainer {
  cursor: pointer !important;
  display: flex !important;
  height: 100%;
  margin: 0 !important;
  outline: none;
  padding: 20px 30px !important;
  position: absolute !important;
  width: 100%;
}
#header ~ .menu-item-content-8 .LPMcontainer .desktop-menu-live-assist {
  width: 100%;
  height: 100%;
}
#header ~ .body-overlay {
  position: fixed;
  z-index: 98;
  width: 100%;
  height: 100%;
  background: #000000;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s linear;
  pointer-events: none;
}
@media (max-width: 767px) {
  #header ~ .body-overlay {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #header ~ .body-overlay {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  #header ~ .body-overlay {
    display: block;
  }
}
@media (min-width: 1381px) {
  #header ~ .body-overlay {
    display: block;
  }
}
#header.hovered ~ .body-overlay {
  opacity: .5;
  pointer-events: auto;
}
@media (max-width: 767px) {
  #header {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #header {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  #header {
    display: flex;
  }
}
@media (min-width: 1381px) {
  #header {
    display: flex;
  }
}
@media (min-width: 1381px) {
  #header .main {
    flex-flow: row;
    justify-content: flex-start;
  }
  #header .main .logo {
    margin-right: 30px;
    align-items: center;
    display: flex;
    width: 380px;
  }
}
@media (max-width: 1580px) {
  #header {
    height: 115px;
  }
  #header .main {
    flex-flow: column;
    justify-content: space-between;
  }
  #header .main .logo {
    margin-top: 20px;
    width: 325px;
  }
  #header .menu-item a,
  #header .menu-item span {
    padding: 10px 5px;
  }
  #header .settings {
    justify-content: flex-start;
    padding-top: 30px;
  }
}
#mobile-header {
  height: 49px;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  justify-content: space-between;
  background-color: #061c38;
  padding: 10px 15px;
  align-items: center;
  z-index: 98;
  font-size: 14px;
}
@media (max-width: 767px) {
  #mobile-header {
    display: flex;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #mobile-header {
    display: flex;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  #mobile-header {
    display: none;
  }
}
@media (min-width: 1381px) {
  #mobile-header {
    display: none;
  }
}
#mobile-header .menu-icon {
  color: #fff;
  flex-basis: 25%;
}
#mobile-header .logo {
  flex-basis: 50%;
  text-align: center;
}
#mobile-header .logo img {
  display: block;
  margin: 0 auto;
  max-height: 49px;
  max-width: 100%;
}
#mobile-header .cart {
  position: relative;
  color: #fff;
  flex-basis: 25%;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
}
#mobile-header .cart .cart-items-count {
  display: none;
  width: 16px;
  padding: 2px;
  position: absolute;
  right: 2px;
  top: -3px;
  text-align: center;
  font: 10px "Lato", Tahoma;
  background-color: #c70607;
  border-radius: 50%;
}
.mobile-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: 101;
  transition: left .2s linear;
}
.mobile-menu.opened {
  left: 0;
  display: flex;
  flex-direction: column;
}
.mobile-menu.opened ~ #body-main {
  display: none;
}
.mobile-menu .logo {
  padding: 10px 10px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #061c38;
}
.mobile-menu .logo a,
.mobile-menu .logo img {
  width: 250px;
  display: block;
}
.mobile-menu .logo .icon-close {
  color: #fff;
  font: 28px 'Zapf Dingbats';
}
.mobile-menu .account {
  padding: 10px;
  background-color: #061c38;
}
.mobile-menu .account .user-name {
  display: inline-block;
  position: relative;
  color: #ffc107;
  font-family: "Lato", Tahoma;
  margin-bottom: 6px;
}
.mobile-menu .account .user-account-links {
  font-size: 16px;
  font-family: "Lato", Tahoma;
  color: #fff;
}
.mobile-menu .account .user-account-links a {
  display: inline-block;
  margin: 2px 5px;
  color: #fff;
  text-decoration: none;
}
.mobile-menu .account .user-account-links a:first-of-type {
  margin-left: 0;
}
.mobile-menu > .menu-content {
  height: 100%;
  background: #fff;
  overflow-y: scroll;
}
.mobile-menu > .menu-content ul {
  padding: 10px 0;
}
.mobile-menu > .menu-content ul li {
  padding: 20px 30px;
  position: relative;
}
.mobile-menu > .menu-content ul li.border-bottom {
  border-bottom: 1px solid #777777;
}
.mobile-menu > .menu-content ul li.country {
  padding: 10px 30px 14px;
  border-top: 1px solid #777777;
}
.mobile-menu > .menu-content ul li.country a {
  display: flex;
  align-items: center;
}
.mobile-menu > .menu-content ul li a {
  color: #061c38;
  font: 600 16px "Lato", Tahoma;
  display: block;
  text-decoration: none;
}
.mobile-menu > .menu-content ul li a .icon-angle-right {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -8px;
}
.mobile-menu > .menu-item-content {
  height: calc(100% - 86px);
  top: 86px;
  width: 100%;
  right: -200%;
  position: absolute;
  background: #fff;
  transition: right 0.2s linear;
  overflow-y: scroll;
}
.mobile-menu > .menu-item-content > .title {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #fff;
  background: #2092C9;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.mobile-menu > .menu-item-content > .title a {
  font-family: "Lato", Tahoma;
  color: #fff;
  text-decoration: none;
}
.mobile-menu > .menu-item-content > .title a i {
  margin-right: 5px;
}
.mobile-menu > .menu-item-content > .title span {
  font-family: "Lato", Tahoma;
  font-weight: 600;
}
.mobile-menu > .menu-item-content ul {
  margin-top: 36px;
  list-style-type: none;
  padding: 0;
}
.mobile-menu > .menu-item-content ul li {
  padding: 20px 30px;
  position: relative;
}
.mobile-menu > .menu-item-content ul li.title {
  background: #81CEEC;
  padding: 20px;
  color: #061c38;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #777777;
  border-left: 0;
  border-right: 0;
}
.mobile-menu > .menu-item-content ul li a {
  color: #061c38;
  font: 600 16px "Lato", Tahoma;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.mobile-menu > .menu-item-content ul li a .icon-country-us {
  background: url('/assets/compiled/images/flags/32/United-States.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-at {
  background: url('/assets/compiled/images/flags/32/Austria.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-de_AT {
  background: url('/assets/compiled/images/flags/32/Germany.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-fr {
  background: url('/assets/compiled/images/flags/32/France.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-de {
  background: url('/assets/compiled/images/flags/32/Germany.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-uk {
  background: url('/assets/compiled/images/flags/32/United-Kingdom.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-de_BE {
  background: url('/assets/compiled/images/flags/32/Germany.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-be {
  background: url('/assets/compiled/images/flags/32/Belgium.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-es {
  background: url('/assets/compiled/images/flags/32/Spain.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-eu {
  background: url('/assets/compiled/images/flags/32/European-Union.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-de_LU {
  background: url('/assets/compiled/images/flags/32/Germany.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-lu {
  background: url('/assets/compiled/images/flags/32/Luxembourg.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-nl {
  background: url('/assets/compiled/images/flags/32/Netherlands.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-it {
  background: url('/assets/compiled/images/flags/32/Italy.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-de_CH {
  background: url('/assets/compiled/images/flags/32/Germany.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-ch {
  background: url('/assets/compiled/images/flags/32/Switzerland.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-cz {
  background: url('/assets/compiled/images/flags/32/Czech-Republic.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-hi {
  background: url('/assets/compiled/images/flags/32/India.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-ta {
  background: url('/assets/compiled/images/flags/32/India.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-pl {
  background: url('/assets/compiled/images/flags/32/Poland.png');
}
.mobile-menu > .menu-item-content ul li a .icon-country-ru {
  background: url('/assets/compiled/images/flags/32/Russia.png');
}
.mobile-menu.logged-in > .menu-item-content {
  height: calc(100% - 110px);
  top: 110px;
}
.mobile-menu.active-menu-1 > .menu-item-content-1,
.mobile-menu.active-menu-5 > .menu-item-content-5,
.mobile-menu.active-menu-6 > .menu-item-content-6,
.mobile-menu.active-menu-7 > .menu-item-content-7,
.mobile-menu.active-menu-8 > .menu-item-content-8,
.mobile-menu.active-menu-11 > .menu-item-content-11 {
  right: 0;
}
.mobile-menu > .menu-content .icon-country,
.mobile-menu > .menu-item-content-11 .icon-country {
  width: 32px;
  height: 32px;
  display: inline-block;
  margin-right: 10px;
}
.mobile-menu > .menu-content .icon-country-us,
.mobile-menu > .menu-item-content-11 .icon-country-us {
  background: url('/assets/compiled/images/flags/32/United-States.png');
}
.mobile-menu > .menu-content .icon-country-at,
.mobile-menu > .menu-item-content-11 .icon-country-at {
  background: url('/assets/compiled/images/flags/32/Austria.png');
}
.mobile-menu > .menu-content .icon-country-de_AT,
.mobile-menu > .menu-item-content-11 .icon-country-de_AT {
  background: url('/assets/compiled/images/flags/32/Germany.png');
}
.mobile-menu > .menu-content .icon-country-fr,
.mobile-menu > .menu-item-content-11 .icon-country-fr {
  background: url('/assets/compiled/images/flags/32/France.png');
}
.mobile-menu > .menu-content .icon-country-de,
.mobile-menu > .menu-item-content-11 .icon-country-de {
  background: url('/assets/compiled/images/flags/32/Germany.png');
}
.mobile-menu > .menu-content .icon-country-uk,
.mobile-menu > .menu-item-content-11 .icon-country-uk {
  background: url('/assets/compiled/images/flags/32/United-Kingdom.png');
}
.mobile-menu > .menu-content .icon-country-de_BE,
.mobile-menu > .menu-item-content-11 .icon-country-de_BE {
  background: url('/assets/compiled/images/flags/32/Germany.png');
}
.mobile-menu > .menu-content .icon-country-be,
.mobile-menu > .menu-item-content-11 .icon-country-be {
  background: url('/assets/compiled/images/flags/32/Belgium.png');
}
.mobile-menu > .menu-content .icon-country-es,
.mobile-menu > .menu-item-content-11 .icon-country-es {
  background: url('/assets/compiled/images/flags/32/Spain.png');
}
.mobile-menu > .menu-content .icon-country-eu,
.mobile-menu > .menu-item-content-11 .icon-country-eu {
  background: url('/assets/compiled/images/flags/32/European-Union.png');
}
.mobile-menu > .menu-content .icon-country-de_LU,
.mobile-menu > .menu-item-content-11 .icon-country-de_LU {
  background: url('/assets/compiled/images/flags/32/Germany.png');
}
.mobile-menu > .menu-content .icon-country-lu,
.mobile-menu > .menu-item-content-11 .icon-country-lu {
  background: url('/assets/compiled/images/flags/32/Luxembourg.png');
}
.mobile-menu > .menu-content .icon-country-nl,
.mobile-menu > .menu-item-content-11 .icon-country-nl {
  background: url('/assets/compiled/images/flags/32/Netherlands.png');
}
.mobile-menu > .menu-content .icon-country-it,
.mobile-menu > .menu-item-content-11 .icon-country-it {
  background: url('/assets/compiled/images/flags/32/Italy.png');
}
.mobile-menu > .menu-content .icon-country-de_CH,
.mobile-menu > .menu-item-content-11 .icon-country-de_CH {
  background: url('/assets/compiled/images/flags/32/Germany.png');
}
.mobile-menu > .menu-content .icon-country-ch,
.mobile-menu > .menu-item-content-11 .icon-country-ch {
  background: url('/assets/compiled/images/flags/32/Switzerland.png');
}
.mobile-menu > .menu-content .icon-country-cz,
.mobile-menu > .menu-item-content-11 .icon-country-cz {
  background: url('/assets/compiled/images/flags/32/Czech-Republic.png');
}
.mobile-menu > .menu-content .icon-country-hi,
.mobile-menu > .menu-item-content-11 .icon-country-hi {
  background: url('/assets/compiled/images/flags/32/India.png');
}
.mobile-menu > .menu-content .icon-country-ta,
.mobile-menu > .menu-item-content-11 .icon-country-ta {
  background: url('/assets/compiled/images/flags/32/India.png');
}
.mobile-menu > .menu-content .icon-country-pl,
.mobile-menu > .menu-item-content-11 .icon-country-pl {
  background: url('/assets/compiled/images/flags/32/Poland.png');
}
.mobile-menu > .menu-content .icon-country-ru,
.mobile-menu > .menu-item-content-11 .icon-country-ru {
  background: url('/assets/compiled/images/flags/32/Russia.png');
}
.mobile-menu > .menu-item-content-8 ul li.border-bottom {
  border-bottom: 1px solid #777777;
}
.mobile-menu > .menu-item-content-8 ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-menu > .menu-item-content-8 ul li a .desc span {
  display: block;
}
.mobile-menu > .menu-item-content-8 ul li a .desc .blue {
  color: #01aee3;
}
.mobile-menu > .menu-item-content-8 ul li a .icon {
  flex-basis: 30px;
  flex-shrink: 0;
}
.mobile-menu > .menu-item-content-11 ul li {
  padding: 10px 30px 14px;
}
.mobile-menu .LPMcontainer {
  cursor: pointer !important;
  display: flex !important;
  height: 100%;
  margin: 0 !important;
  outline: none;
  padding: 20px 30px !important;
  position: absolute !important;
  width: 100%;
}
.mobile-menu .LPMcontainer .mobile-menu-live-assist {
  width: 100%;
  height: 100%;
}
#status-header {
  display: none;
  position: relative;
  z-index: 100;
  padding: 2px;
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  #status-header.alert {
    display: block;
  }
}
@media (min-width: 1381px) {
  #status-header.alert {
    display: block;
  }
}
.vip-level-star {
  top: -8px;
  left: -35px;
  width: 30px;
  height: 30px;
  position: absolute;
  display: block;
}
@media (max-width: 767px) {
  .vip-level-star {
    display: none;
  }
}
.vip-level-star.mobile {
  display: none;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .vip-level-star.mobile {
    display: block;
    left: auto;
    right: -40px;
  }
}
.vip-level-star.animate {
  -o-animation: blink 1s;
  -webkit-animation: blink 1s;
  animation: blink 1s;
}
@-webkit-keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes blink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
.vip-level-star.level-bronze {
  background-image: url("/assets/images/vip/Star_Bronze.png");
}
.vip-level-star.level-silver {
  background-image: url("/assets/images/vip/Star_Silver.png");
}
.vip-level-star.level-gold {
  background-image: url("/assets/images/vip/Star_Gold.png");
}
.vip-level-star.level-platinum {
  background-image: url("/assets/images/vip/Star_Platinum.png");
}
.vip-level-bar {
  width: 100%;
  height: 15px;
  position: relative;
  overflow: hidden;
}
.vip-level-bar:before {
  content: '';
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateX(100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(125, 185, 232, 0) 100%);
  -o-animation: slide 10s infinite ease-in-out;
  -webkit-animation: slide 10s infinite ease-in-out;
  animation: slide 10s infinite ease-in-out;
}
@-webkit-keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  30% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  30% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.vip-level-bar.level-bronze {
  background-image: url("/assets/images/vip/Line_Bronze-min.png");
}
.vip-level-bar.level-silver {
  background-image: url("/assets/images/vip/Line_Silver-min.png");
}
.vip-level-bar.level-gold {
  background-image: url("/assets/images/vip/Line_Gold-min.png");
}
.vip-level-bar.level-platinum {
  background-image: url("/assets/images/vip/Line_Platinum-min.png");
}
.special-offer {
  display: flex;
  justify-content: space-between;
  color: #01aee3;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.special-offer .special-offer-code {
  color: #737375;
}
.special-offer .special-offer-link {
  width: 20px;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  color: #fff;
  background-color: #01aee3;
  font-family: FontAwesome;
}
.special-offer .special-offer-link:after {
  content: "\f0da";
}
.special-offer .special-offer-link:hover {
  color: #fff;
}
.special-offer-valid {
  font-size: 14px;
  text-align: center;
  color: #737375;
}
.special-offer-desc {
  font-size: 14px;
  text-align: center;
  color: #737375;
  width: 166px;
}

#vip-popup {
  text-align: center;
}
#vip-popup .vip-popup-star {
  margin-top: -60px;
}
#vip-popup.mobile .vip-popup-star {
  margin-top: 0;
}
#vip-popup h3 {
  font-size: 17.5px;
}
#vip-popup p {
  margin-bottom: 30px;
}
#vip-popup .dialog-popup-container,
#vip-popup .dialog-popup-body {
  overflow: visible;
}
#vip-popup .dialog-popup-toolbar-close {
  margin-top: -22px;
}
#vip-popup .dialog-popup-container {
  position: absolute;
  transition: transform 1s;
  transform-origin: 50% 50%;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .dialog-padding,
  .dialog-popup-header-title,
  .dialog-popup-body,
  .dialog-popup-footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .dialog-padding,
  .dialog-popup-header-title,
  .dialog-popup-body,
  .dialog-popup-footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (min-width: 1381px) {
  .dialog-padding,
  .dialog-popup-header-title,
  .dialog-popup-body,
  .dialog-popup-footer {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .dialog-padding,
  .dialog-popup-header-title,
  .dialog-popup-body,
  .dialog-popup-footer {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.dialog-popup-mask {
  padding: 10px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .3s ease;
}
.dialog-popup-container {
  max-width: 95%;
  padding: 3px;
  background-color: #fff;
  border-radius: 2px;
  transition: all .3s ease;
  font: 14px "Lato", Tahoma;
  box-shadow: 0 0 8px rgba(68, 68, 68, 0.6);
  max-height: 100%;
  overflow: auto;
}
.dialog-popup-toolbar {
  z-index: 101;
  position: absolute;
  right: 8px;
  top: 0;
}
.dialog-popup-toolbar-close {
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url("/assets/images/site/onp_main_sprite.png") -292px -26px no-repeat;
}
.dialog-popup-header {
  position: relative;
  margin-top: 10px;
}
.dialog-popup-header-title {
  padding-right: 30px;
  margin: 0 0 10px;
  font-family: "Lato", Tahoma;
  font-weight: normal;
  line-height: 1em;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
}
.dialog-popup-body {
  min-width: 100px;
  max-width: 100%;
  padding-bottom: 30px;
  overflow: auto;
}
.dialog-popup-footer {
  padding-bottom: 30px;
}

#vip-tooltip {
  text-align: center;
}
#vip-tooltip .vip-popup-star {
  margin-top: -60px;
}
#vip-tooltip.mobile .vip-popup-star {
  margin-top: 0;
}
#vip-tooltip h3 {
  font-size: 17.5px;
}
#vip-tooltip .dialog-popup-body {
  padding-bottom: 12px;
  padding-top: 60px;
}
#vip-tooltip .dialog-popup-container {
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 84px;
  overflow: visible;
}
#vip-tooltip .dialog-popup-container::before {
  border: solid transparent;
  content: " ";
  position: absolute;
  border-bottom-color: #fff;
  border-width: 28px;
  margin-left: -106px;
  top: -46px;
}

.footer-blocks-container:before,
.footer-blocks-container:after {
  content: " ";display: table;
}
.footer-blocks-container:after {
  clear: both;
}
.footer-blocks-container:before,
.footer-blocks-container:after {
  content: " ";display: table;line-height: 0;
}
.footer-blocks-container:after {
  clear: both;
}
.text-simple {
  appearance: none !important;
  background: #fff !important;
  border: 1px solid #d1d1d2 !important;
  box-shadow: none !important;
  color: #737375 !important;
  flex: 1 1 auto !important;
  font: 14px "Lato", Tahoma !important;
  height: 36px !important;
  line-height: 26px !important;
  margin: 0 !important;
  outline: none;
  transition: border .15s linear !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}
.text-simple:not(.no-default-padding) {
  padding: 5px 10px !important;
}
.text-simple:focus,
.text-simple:hover {
  border-color: #01aee3 !important;
  box-shadow: none !important;
}
.text-simple::placeholder {
  color: #acacac;
}
.btn-arrow:after {
  content: "\f04b";
  font-family: FontAwesome;
  font-size: .8em;
  margin-left: .6em;
  vertical-align: bottom;
}
.btn-secondary {
  border: none;
  color: #fff;
  cursor: pointer;
  line-height: 1em;
  padding: 6px 12px;
  text-decoration: none;
  transition: background 0.3s ease;
}
.btn-secondary,
.btn-secondary:focus {
  background: #01aee3;
}
.btn-secondary:hover {
  background: #01bbf4;
}
.btn-secondary:active {
  background: #0386dd;
}
@media (max-width: 767px) {
  .mobile-adjusted #footer-main {
    display: none;
  }
}
.footer-blocks-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 21px;
  padding-right: 21px;
}
@media (min-width: 768px) {
  .footer-blocks-container {
    width: 753px;
  }
}
@media (min-width: 1024px) {
  .footer-blocks-container {
    width: 1009px;
  }
}
@media (min-width: 1381px) {
  .footer-blocks-container {
    width: 1366px;
  }
}
#footer-main {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #e4e4e4;
  color: #777;
  font-size: 14px;
  position: relative;
  margin-top: 20px;
}
@media (max-width: 767px) {
  #footer-main {
    border-top: 1px solid #d1d1d2;
    background: #e6e6e6;
    padding-top: 0;
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #footer-main {
    border-top: 1px solid #d1d1d2;
    background: #e6e6e6;
    padding-top: 0;
    margin-top: 0;
  }
}
#footer-main a {
  color: #777777;
  border: none;
  text-decoration: none;
}
#footer-main h3,
#footer-main .footer-block-title {
  font-family: "Lato", Tahoma;
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  line-height: 1em;
  color: #01aee3;
  padding: 9px 0 5px;
  display: block;
}
@media (max-width: 767px) {
  #footer-main h3,
  #footer-main .footer-block-title {
    text-transform: uppercase;
    color: #777;
    font-weight: bold;
    padding-left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #footer-main h3,
  #footer-main .footer-block-title {
    text-transform: uppercase;
    color: #777;
    font-weight: bold;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  #footer-main h3,
  #footer-main .footer-block-title {
    font-size: 14px;
    padding-left: 16px;
    text-indent: 0;
    line-height: 140%;
  }
}
#footer-main h4 {
  margin: 0;
  font-size: 1.29em;
  font-weight: normal;
  line-height: 1em;
  color: #01aee3;
  padding: 2px 0 15px 0;
}
#footer-main p {
  margin-bottom: 2px;
}
#footer-main .follow-toolbox {
  padding-bottom: 20px;
  margin-left: 0;
}
@media (max-width: 767px) {
  #footer-main .follow-toolbox {
    padding-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #footer-main .follow-toolbox {
    padding-bottom: 0;
  }
}
#footer-main .follow-toolbox a {
  margin-bottom: 5px;
  line-height: initial;
}
#footer-main .follow-toolbox a .icon-wrapper {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  line-height: 32px;
  height: 32px;
  width: 32px;
}
@media (max-width: 767px) {
  #footer-main .follow-toolbox a .icon-wrapper {
    height: 24px;
    width: 24px;
    line-height: 24px;
  }
}
#footer-main .follow-toolbox .button-follow {
  padding: 0 2px;
  float: left;
  cursor: pointer;
}
#footer-main .follow-toolbox .icon {
  width: 32px;
  height: 32px;
  fill: #fff;
  border: 0;
}
@media (max-width: 767px) {
  #footer-main .follow-toolbox .icon {
    height: 24px;
    width: 24px;
    line-height: 24px;
  }
}
#footer-main .follow-toolbox .follow-label {
  display: none;
}
#footer-main .follow-toolbox .follow-clear {
  clear: both;
}
#footer-main .follow-toolbox .button_facebook_follow .icon-wrapper {
  background-color: #3b5998;
}
#footer-main .follow-toolbox .button_twitter_follow .icon-wrapper {
  background-color: #1da1f2;
}
#footer-main .follow-toolbox .button_youtube_follow .icon-wrapper {
  background-color: #cd201f;
}
#footer-main .follow-toolbox .button_vimeo_follow .icon-wrapper {
  background-color: #1ab7ea;
}
#footer-main .follow-toolbox .button_pinterest_follow .icon-wrapper {
  background-color: #cb2027;
}
#footer-main .follow-toolbox .button_instagram_follow .icon-wrapper {
  background-color: #e03566;
}
@media (max-width: 767px) {
  #footer-main .footer-resources {
    clear: left;
  }
}
#footer-main .signup-container h3 {
  display: block;
  padding-bottom: 6px;
  width: 250px;
}
@media (max-width: 767px) {
  #footer-main .signup-container h3 {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #footer-main .signup-container h3 {
    display: none !important;
  }
}
#footer-main .footer-blocks {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  #footer-main .footer-blocks {
    flex-direction: column;
    margin-bottom: 6px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #footer-main .footer-blocks {
    flex-direction: column;
    margin-bottom: 6px;
  }
}
#footer-main .footer-social {
  margin-top: 2px;
}
#footer-main .footer-social .footer-block {
  flex-basis: 48%;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  #footer-main .footer-social .footer-block {
    border-top: 1px solid #acacac;
  }
}
@media (min-width: 1381px) {
  #footer-main .footer-social .footer-block {
    border-top: 1px solid #acacac;
  }
}
@media (max-width: 767px) {
  #footer-main .footer-social {
    display: flex;
    flex-direction: column;
  }
  #footer-main .footer-social .social-container {
    order: 2;
    border-bottom: none;
    border-top: 1px solid #acacac;
    padding-top: 10px;
    margin-top: 10px;
  }
  #footer-main .footer-social .social-container h3 {
    display: none;
  }
  #footer-main .footer-social .social-container .copyright {
    margin: 4px 0 10px;
  }
  #footer-main .footer-social .signup-container {
    order: 1;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #footer-main .footer-social {
    display: flex;
    flex-direction: column;
  }
  #footer-main .footer-social .social-container {
    order: 2;
    border-bottom: none;
    border-top: 1px solid #acacac;
    padding-top: 10px;
    margin-top: 10px;
  }
  #footer-main .footer-social .social-container h3 {
    display: none;
  }
  #footer-main .footer-social .social-container .copyright {
    margin: 4px 0 10px;
  }
  #footer-main .footer-social .signup-container {
    order: 1;
  }
}
div.form-sign-wrap {
  width: 250px;
  float: left;
}
@media (max-width: 767px) {
  div.form-sign-wrap {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  div.form-sign-wrap {
    width: 100%;
  }
}
@media (max-width: 767px) {
  div.form-sign-wrap h3 {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  div.form-sign-wrap h3 {
    display: none;
  }
}
.footer-seal {
  border: none !important;
  font-family: "Lato", Tahoma;
  float: left;
  margin-top: 10px;
}
@media (min-width: 1381px) {
  .footer-seal {
    margin-left: 92px;
  }
}
.footer-seal a.shopper-link img {
  border: 0;
}
@media (max-width: 767px) {
  .footer-seal {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .footer-seal {
    display: none !important;
  }
}
.footer-seal .shopper-approved-footer {
  margin-top: 8px;
  width: 180px;
  line-height: 100%;
}
.footer-collapsed-dots {
  text-align: center;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .footer-collapsed-dots {
    display: none !important;
  }
}
@media (min-width: 1381px) {
  .footer-collapsed-dots {
    display: none !important;
  }
}
.footer-menu input {
  display: none;
}
@media (max-width: 767px) {
  .footer-block {
    padding-left: 0;
  }
}
.footer-menu .footer-block {
  flex-basis: 22%;
}
@media (max-width: 767px) {
  .footer-menu .footer-block {
    border-bottom: 1px solid #acacac;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .footer-menu .footer-block {
    border-bottom: 1px solid #acacac;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .footer-menu .footer-block {
    border-top: 1px solid #acacac;
  }
}
@media (min-width: 1381px) {
  .footer-menu .footer-block {
    border-top: 1px solid #acacac;
  }
}
.footer-menu .menu-item-content {
  list-style: none;
  margin: 0 0 0 2px;
  padding: 0;
}
@media (max-width: 767px) {
  .footer-menu .menu-item-content {
    background: transparent;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .footer-menu .menu-item-content {
    background: transparent;
  }
}
.footer-menu .menu-item-content li {
  line-height: 1.58em;
}
@media (max-width: 767px) {
  .footer-menu label {
    position: relative;
    z-index: 20;
    display: block;
    cursor: pointer;
  }
  .footer-menu label:before {
    content: '\f106';
    font-family: FontAwesome;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 8px;
  }
  .footer-menu input:checked + label:before {
    content: '\f107';
  }
  .footer-menu .menu-item-content {
    margin: 0 0 0 14px;
    overflow: hidden;
    height: 0px;
    position: relative;
    z-index: 10;
    transition: height 0.3s ease-in-out;
  }
  .footer-menu input:checked ~ .menu-item-content {
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .footer-menu label {
    position: relative;
    z-index: 20;
    display: block;
    cursor: pointer;
  }
  .footer-menu label:before {
    content: '\f106';
    font-family: FontAwesome;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 8px;
  }
  .footer-menu input:checked + label:before {
    content: '\f107';
  }
  .footer-menu .menu-item-content {
    margin: 0 0 0 14px;
    overflow: hidden;
    height: 0px;
    position: relative;
    z-index: 10;
    transition: height 0.3s ease-in-out;
  }
  .footer-menu input:checked ~ .menu-item-content {
    height: auto;
  }
}

.cookie-policy {
  bottom: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  background: #01aee3;
  z-index: 99;
}
.cookie-policy-desc {
  color: #fff;
}
@media (max-width: 767px) {
  .cookie-policy-desc {
    padding: 0 35px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cookie-policy-desc {
    padding: 0 35px;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .cookie-policy-desc {
    padding: 15px 35px;
  }
}
@media (min-width: 1381px) {
  .cookie-policy-desc {
    padding: 15px 35px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cookie-policy-desc {
    font-size: 18px;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .cookie-policy-desc {
    font-size: 18px;
  }
}
@media (min-width: 1381px) {
  .cookie-policy-desc {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .cookie-policy-desc {
    font-size: 14px;
  }
}
.cookie-policy-desc a {
  color: #ffc107;
}
.cookie-policy-close {
  cursor: pointer;
  font: 28px/28px 'Zapf Dingbats';
  height: 26px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 26px;
  color: #fff;
}

.home-page-slider:before,
.home-page-slider:after {
  content: " ";display: table;
}
.home-page-slider:after {
  clear: both;
}
.home-page-slider:before,
.home-page-slider:after {
  content: " ";display: table;line-height: 0;
}
.home-page-slider:after {
  clear: both;
}
body {
  font-size: 14px;
}
.body-content {
  display: flex;
  flex-direction: column;
}
#homepage {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  section.main-seo {
    margin-top: 15px;
    margin-bottom: 10px;
    position: relative;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  section.main-seo {
    margin-top: 15px;
    margin-bottom: 10px;
    position: relative;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  section.main-seo {
    margin-left: -21px;
    margin-right: -21px;
    margin-top: 24px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1381px) {
  section.main-seo {
    margin-left: -21px;
    margin-right: -21px;
    margin-top: 24px;
    margin-bottom: 20px;
  }
}
section.main-seo .main-seo-info {
  position: relative;
  min-height: 1px;
  padding-left: 21px;
  padding-right: 21px;
}
@media (min-width: 1024px) {
  section.main-seo .main-seo-info {
    float: left;
    width: 100%;
  }
}
@media (min-width: 1381px) {
  section.main-seo .main-seo-info {
    float: left;
    width: 100%;
  }
}
section.main-seo h4 {
  font-size: 120%;
  text-transform: capitalize;
  margin: 0;
}
section.main-seo p {
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 10px;
}
.content-covid h3 {
  text-align: center;
  margin-bottom: 10px;
}
.content-social {
  margin-top: 20px;
  padding-top: 10px;
  text-align: center;
}
.content-social .partnering-providers-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* social media button intra-spacing */
}
.content-social .partnering-providers-container > div {
  padding: 0 7px;
}
@media (max-width: 767px) {
  .content-social {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .content-social {
    display: none;
  }
}
@media (max-width: 767px) {
  section.main-seo-header {
    order: -1;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  section.main-seo-header {
    order: -1;
  }
}
section.main-seo-header h1 {
  font-weight: normal;
  line-height: 1em;
  position: relative;
  text-align: center;
  font-family: "Lato", Tahoma;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  section.main-seo-header h1 {
    font-size: 125%;
    margin: 20px 0 10px 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  section.main-seo-header h1 {
    font-size: 125%;
    margin: 20px 0 10px 0;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  section.main-seo-header h1 {
    font-size: 180%;
    margin: 25px 0;
  }
}
@media (min-width: 1381px) {
  section.main-seo-header h1 {
    font-size: 180%;
    margin: 25px 0;
  }
}
section.main-seo-header h1 span {
  display: inline-block;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  section.main-seo {
    margin-left: -21px;
    margin-right: -21px;
    margin-top: 24px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1381px) {
  section.main-seo {
    margin-left: -21px;
    margin-right: -21px;
    margin-top: 24px;
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  section.main-seo .seo-read-more {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  section.main-seo .seo-read-more {
    display: none;
  }
}
@media (min-width: 1381px) {
  section.main-seo .seo-read-more {
    display: none;
  }
}
@media (max-width: 767px) {
  section.main-seo .seo-read-more {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 60px 0 20px 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), #fff);
    color: #01aee3;
    text-decoration: none;
  }
}
@media (max-width: 767px) {
  section.main-seo .main-seo-info.xs-seo-collapsed {
    max-height: 360px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  section.main-seo h4 {
    font-size: 120%;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  section.main-seo h4 {
    font-size: 120%;
  }
}
@media (min-width: 1381px) {
  section.main-seo h4 {
    font-size: 120%;
  }
}
@media (max-width: 767px) {
  section.main-seo h4 {
    font-size: 14px;
  }
}
.home-page-slider {
  margin-right: auto;
  margin-left: auto;
  padding-left: 21px;
  padding-right: 21px;
}
@media (min-width: 768px) {
  .home-page-slider {
    width: 753px;
  }
}
@media (min-width: 1024px) {
  .home-page-slider {
    width: 1009px;
  }
}
@media (min-width: 1381px) {
  .home-page-slider {
    width: 1366px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .home-page-slider {
    margin-top: 10px;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .home-page-slider {
    margin-top: 10px;
  }
}
@media (min-width: 1381px) {
  .home-page-slider {
    margin-top: 10px;
  }
}
.link-container__row {
  padding: 10px 0;
}
.link-container__row a {
  display: block;
  border: 1px solid #ccc;
  background-color: #e6e6e6;
  text-decoration: none;
  color: #737375;
  font-size: 18pt;
  font-weight: bold;
  padding: 11px;
  text-align: center;
}
.link-container__row a:hover {
  color: #3e3e3f;
}
section.main-seo h4 {
  font-size: 14px;
  line-height: 20px;
  text-transform: initial;
  margin: 0;
}
section.main-seo h2 {
  font-size: 16px;
  line-height: 24px;
}
section.main-seo p {
  font-size: 14px;
  margin-bottom: 15px;
}
section.main-seo a {
  color: #01aee3;
  text-decoration: none;
}
.link-container__row a:hover {
  -webkit-box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.15);
}

/*
 *  Responsive Utilities
 */
.shopper-approved {
  align-items: center;
  background-color: #3e3e3f;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: "Lato", Tahoma;
  justify-content: flex-start;
  line-height: 22px;
  margin: 10px 0;
  height: 50px;
  padding: 5px 0;
  font-size: 14px;
}
@media (max-width: 767px) {
  .shopper-approved {
    display: none !important;
  }
}
.shopper-approved .shopper-approved-rating-container {
  display: inline-block;
  text-align: center;
  margin: 0 20px;
  line-height: 115%;
}
.shopper-approved .shopper-approved-rating-container i {
  color: #ff9100;
  margin: 0 1px;
  font-size: 11px;
}
.shopper-approved .shopper-approved-rating-count {
  font-size: 150%;
}
.shopper-approved .shopper-approved-rating-title {
  font-size: 100%;
  text-transform: uppercase;
}
.shopper-approved .shopper-approved-payment-methods-container {
  margin: 0 0 0 auto;
  padding: 10px 15px 5px 0;
}
.shopper-approved .shopper-approved-payment-methods-container .shopper-approved-payment-methods-image {
  max-width: 100%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .shopper-approved .shopper-approved-payment-methods-container {
    flex-basis: 300px;
  }
}

.circle {
  border-radius: 50%;
}
.rounded {
  border-radius: 12px;
}
.featured-prods-title {
  text-align: center;
  color: #737375;
}
.featured-prods-title .featured-prods-title-hr {
  width: 100%;
  height: 2px;
  border-top: 1px solid #737375;
  margin-top: 29px;
}
.featured-prods-title h3,
.featured-prods-title .featured-prods-title-text {
  display: inline-block;
  margin: -31px auto 30px;
  padding: 0 20px;
  font-size: 36px;
  line-height: 35px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #fff;
}
@media (max-width: 767px) {
  .featured-prods-title {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .featured-prods-title {
    display: none;
  }
}
.featured-prods-dynamic {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: "Lato", Tahoma;
  color: #737375;
}
.featured-prods-dynamic::after {
  content: '';
  flex: auto;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .featured-prods-dynamic {
    width: 102%;
    margin-left: -1%;
  }
}
@media (min-width: 1381px) {
  .featured-prods-dynamic {
    width: 102%;
    margin-left: -1%;
  }
}
.featured-prods-dynamic .featured-prod-container {
  display: none;
}
@media (max-width: 767px) {
  .featured-prods-dynamic .featured-prod-container {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .featured-prods-dynamic .featured-prod-container {
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .featured-prods-dynamic .featured-prod-container {
    padding: 1%;
  }
}
@media (min-width: 1381px) {
  .featured-prods-dynamic .featured-prod-container {
    padding: 1%;
  }
}
@media (max-width: 767px) {
  .featured-prods-dynamic .featured-prod-container {
    vertical-align: top;
    margin: 10px 0;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .featured-prods-dynamic .featured-prod-container {
    width: 33.33333%;
  }
}
@media (min-width: 1381px) {
  .featured-prods-dynamic .featured-prod-container {
    width: 25%;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .featured-prods-dynamic .featured-prod-container.show-on-desktop {
    display: block;
  }
}
@media (min-width: 1381px) {
  .featured-prods-dynamic .featured-prod-container.show-on-desktop {
    display: block;
  }
}
@media (max-width: 767px) {
  .featured-prods-dynamic .featured-prod-container.show-on-tablet {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .featured-prods-dynamic .featured-prod-container.show-on-tablet {
    display: block;
  }
}
.featured-prods-dynamic .featured-prod {
  margin-bottom: 50px;
  cursor: pointer;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .featured-prods-dynamic .featured-prod {
    border: 1px solid #ccc;
  }
}
@media (min-width: 1381px) {
  .featured-prods-dynamic .featured-prod {
    border: 1px solid #ccc;
  }
}
@media (max-width: 767px) {
  .featured-prods-dynamic .featured-prod {
    margin: 0;
    border: 1px solid #e6e6e6;
    padding: 5px 10px 0;
  }
}
.featured-prods-dynamic .featured-prod:hover {
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.15);
}
.featured-prods-dynamic .block-main-image {
  overflow: hidden;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .featured-prods-dynamic .block-main-image {
    min-height: 279px;
  }
}
@media (min-width: 1381px) {
  .featured-prods-dynamic .block-main-image {
    min-height: 279px;
  }
}
@media (max-width: 767px) {
  .featured-prods-dynamic .block-main-image {
    max-height: 165px;
  }
}
.featured-prods-dynamic .block-main-image img {
  width: 100%;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .featured-prods-dynamic .block-main-image .tablet-img {
    display: none;
  }
}
@media (min-width: 1381px) {
  .featured-prods-dynamic .block-main-image .tablet-img {
    display: none;
  }
}
@media (max-width: 767px) {
  .featured-prods-dynamic .block-main-image .tablet-img + .desktop-img {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .featured-prods-dynamic .block-main-image .tablet-img + .desktop-img {
    display: none;
  }
}
.featured-prods-dynamic .custom-block-main-image {
  text-align: center;
  overflow: hidden;
  background-color: #e6e6e6;
}
.featured-prods-dynamic .custom-block-main-image::before {
  content: '';
  margin-top: -2px;
  display: block;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .featured-prods-dynamic .custom-block-main-image {
    line-height: 279px;
    height: 279px;
  }
}
@media (min-width: 1381px) {
  .featured-prods-dynamic .custom-block-main-image {
    line-height: 279px;
    height: 279px;
  }
}
@media (max-width: 767px) {
  .featured-prods-dynamic .custom-block-main-image {
    line-height: 117px;
    height: 117px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .featured-prods-dynamic .custom-block-main-image {
    line-height: 317px;
    height: 317px;
  }
}
.featured-prods-dynamic .custom-block-main-image img {
  margin-left: auto;
  margin-right: auto;
  max-height: 100%;
  max-width: 90%;
  border: 1px solid #ccc;
  vertical-align: middle;
}
.featured-prods-dynamic .custom-block-main-image .tablet-img {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .featured-prods-dynamic .custom-block-main-image .tablet-img {
    display: none !important;
  }
}
@media (min-width: 1381px) {
  .featured-prods-dynamic .custom-block-main-image .tablet-img {
    display: none !important;
  }
}
.featured-prods-dynamic .custom-block-main-image .tablet-img + .desktop-img {
  display: inline;
}
.featured-prods-dynamic .block-text {
  padding: 30px 30px 20px 20px;
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .featured-prods-dynamic .block-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 425px;
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .featured-prods-dynamic .block-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 425px;
    text-align: center;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .featured-prods-dynamic .block-text {
    height: 189px;
  }
}
@media (min-width: 1381px) {
  .featured-prods-dynamic .block-text {
    height: 189px;
  }
}
@media (max-width: 767px) {
  .featured-prods-dynamic .block-text {
    min-height: 40px;
    padding: 0;
  }
}
.featured-prods-dynamic .block-text h3,
.featured-prods-dynamic .block-text .block-title {
  display: inline-block;
  margin: 0 0 10px;
  font-size: 22px;
  font-weight: bold;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .featured-prods-dynamic .block-text h3,
  .featured-prods-dynamic .block-text .block-title {
    font-size: 17.5px;
    margin: 5px 0;
  }
}
.featured-prods-dynamic .block-button {
  position: absolute;
  left: 50%;
  cursor: pointer;
  background: #00aee1;
  background: -moz-linear-gradient(right, #00aee1 0%, #0098c7 100%);
  background: -webkit-gradient(linear, top left, top right, color-stop(0%, #00aee1), color-stop(100%, #0098c7));
  background: -webkit-linear-gradient(left, #00aee1 0%, #0098c7 100%);
  background: -o-linear-gradient(right, #00aee1 0%, #0098c7 100%);
  background: -ms-linear-gradient(right, #00aee1 0%, #0098c7 100%);
  background: linear-gradient(to right, #00aee1 0%, #0098c7 100%);
  border: 2px solid #9ed1f0;
  text-transform: uppercase;
  box-sizing: border-box;
  color: white;
  font-weight: normal;
  padding-top: 5px;
  line-height: 1.428571429;
  bottom: -18px;
  margin-left: -76px;
  width: 153px;
  height: 35px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .featured-prods-dynamic .block-button {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .featured-prods-dynamic .block-button {
    display: none;
  }
}
.featured-prods-dynamic .block-button.block-button-1 {
  background: #01aee3;
}
.featured-prods-dynamic .block-button.block-button-1:hover {
  background: linear-gradient(90deg, #01aee3 0, #0098c7);
}
.featured-prods-dynamic .block-button.block-button-2 {
  background: #c60707;
  border-color: #ff0000;
}
.featured-prods-dynamic .block-button.block-button-2:hover {
  background: #c60707;
}
.featured-prods-dynamic .block-button.block-button-3 {
  background: #9dd1eF;
}
.featured-prods-dynamic .block-button.block-button-3:hover {
  background: linear-gradient(90deg, #9dd1ef 0, #fff);
}
.featured-prods-dynamic .block-button.block-button-4 {
  background: #155a94;
  border-color: #155a94;
}
.featured-prods-dynamic .block-button.block-button-5 {
  background: #f04702;
  border-color: #f04702;
}
.featured-prods-dynamic .block-button.block-button-6 {
  background: #01aee3;
  border-color: #01aee3;
}
.featured-prods-dynamic .block-button.block-button-6:hover {
  background: #01bbf4;
  border-color: #01bbf4;
}
.featured-prods-dynamic .block-description {
  font-size: 14px;
  line-height: 20px;
}
.featured-prods-dynamic .block-control {
  position: absolute;
  right: 0;
}

/* v1.0.5 */
/* Core RS CSS file. 95% of time you shouldn't change anything here. */
.royalSlider {
	width: 600px;
	height: 400px;
	position: relative;
	direction: ltr;
}
.royalSlider > * {
	float: left;
}

.rsWebkit3d .rsSlide {
	-webkit-transform: translateZ(0);
}


.rsWebkit3d .rsSlide,
.rsWebkit3d .rsContainer,
.rsWebkit3d .rsThumbs,
.rsWebkit3d .rsPreloader,
.rsWebkit3d img,
.rsWebkit3d .rsOverflow,
.rsWebkit3d .rsBtnCenterer,
.rsWebkit3d .rsAbsoluteEl,
.rsWebkit3d .rsABlock,
.rsWebkit3d .rsLink {
	-webkit-backface-visibility: hidden;
}
.rsFade.rsWebkit3d .rsSlide,
.rsFade.rsWebkit3d img,
.rsFade.rsWebkit3d .rsContainer {
    -webkit-transform: none;
}
.rsOverflow {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	float: left;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}
.rsVisibleNearbyWrap {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	left: 0;
	top: 0;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}
.rsVisibleNearbyWrap .rsOverflow {
	position: absolute;
	left: 0;
	top: 0;

}
.rsContainer {
	position: relative;
	width: 100%;
	height: 100%;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}

.rsArrow,
.rsThumbsArrow {
	cursor: pointer;
}

.rsThumb {
	float: left;
	position: relative;
}


.rsArrow,
.rsNav,
.rsThumbsArrow {
	opacity: 1;
	-webkit-transition:opacity 0.3s linear;
	-moz-transition:opacity 0.3s linear;
	-o-transition:opacity 0.3s linear;
	transition:opacity 0.3s linear;
}
.rsHidden {
	opacity: 0;
	visibility: hidden;
	-webkit-transition:visibility 0s linear 0.3s,opacity 0.3s linear;
	-moz-transition:visibility 0s linear 0.3s,opacity 0.3s linear;
	-o-transition:visibility 0s linear 0.3s,opacity 0.3s linear;
	transition:visibility 0s linear 0.3s,opacity 0.3s linear;
}


.rsGCaption {
	width: 100%;
	float: left;
	text-align: center;
}

/* Fullscreen options, very important ^^ */
.royalSlider.rsFullscreen {
	position: fixed !important;
	height: auto !important;
	width: auto !important;
	margin: 0 !important;
	padding: 0 !important;
	z-index: 2147483647 !important;
	top: 0 !important;
	left: 0 !important;
	bottom: 0 !important;
	right: 0 !important;
}

.royalSlider .rsSlide.rsFakePreloader {
	opacity: 1 !important;
	-webkit-transition: 0s;
	-moz-transition: 0s;
	-o-transition:  0s;
	transition:  0s;
	display: none;
}

.rsSlide {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	overflow: hidden;
	
	height: 100%;
	width: 100%;
}

.royalSlider.rsAutoHeight,
.rsAutoHeight .rsSlide {
	height: auto;
}

.rsContent {
	width: 100%;
	height: 100%;
	position: relative;
}

.rsPreloader {
	position:absolute;
	z-index: 0;	
}

.rsNav {
	-moz-user-select: -moz-none;
	-webkit-user-select: none;
	user-select: none;
}
.rsNavItem {
	-webkit-tap-highlight-color:rgba(0,0,0,0.25);
}

.rsThumbs {
	cursor: pointer;
	position: relative;
	overflow: hidden;
	float: left;
	z-index: 22;
}
.rsTabs {
	float: left;
	background: none !important;
}
.rsTabs,
.rsThumbs {
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}


.rsVideoContainer {
	/*left: 0;
	top: 0;
	position: absolute;*/
	/*width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	float: left;*/
	width: auto;
	height: auto;
	line-height: 0;
	position: relative;
}
.rsVideoFrameHolder {
	position: absolute;
	left: 0;
	top: 0;
	background: #141414;
	opacity: 0;
	-webkit-transition: .3s;
}
.rsVideoFrameHolder.rsVideoActive {
	opacity: 1;
}
.rsVideoContainer iframe,
.rsVideoContainer video,
.rsVideoContainer embed,
.rsVideoContainer .rsVideoObj {
	position: absolute;
	z-index: 50;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
/* ios controls over video bug, shifting video */
.rsVideoContainer.rsIOSVideo iframe,
.rsVideoContainer.rsIOSVideo video,
.rsVideoContainer.rsIOSVideo embed {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding-right: 44px;
}

.rsABlock {
	left: 0;
	top: 0;
	position: absolute;
	z-index: 15;
	
}

img.rsImg {
	max-width: none;
}

.grab-cursor {
	cursor:url(//stack3.onptest.com/dist/OvernightPrintsUK/images/grab.16a537a7.png) 8 8, move; 
}

.grabbing-cursor{ 
	cursor:url(//stack3.onptest.com/dist/OvernightPrintsUK/images/grabbing.6f17ebd1.png) 8 8, move;
}

.rsNoDrag {
	cursor: auto;
}

.rsLink {
	left:0;
	top:0;
	position:absolute;
	width:100%;
	height:100%;
	display:block;	
	z-index: 20;
	background: url(//stack3.onptest.com/dist/OvernightPrintsUK/images/blank.56398e76.gif);
}

/******************************
*
*  RoyalSlider Default Skin 
*
*    1. Arrows 
*    2. Bullets
*    3. Thumbnails
*    4. Tabs
*    5. Fullscreen button
*    6. Play/close video button
*    7. Preloader
*    8. Caption
*    
*  Sprite: 'rs-default.png'
*  Feel free to edit anything
*  If you don't some part - just delete it
* 
******************************/


/* Background */
.rsDefault,
.rsDefault .rsOverflow,
.rsDefault .rsSlide,
.rsDefault .rsVideoFrameHolder,
.rsDefault .rsThumbs {
	background: #151515;
	color: #FFF;
}


/***************
*
*  1. Arrows
*
****************/

.rsDefault .rsArrow {
	height: 100%;
	width: 44px;
	position: absolute;
	display: block;
	cursor: pointer;
	z-index: 21;
}
.rsDefault.rsVer .rsArrow {
	width: 100%;
	height: 44px;
	
}
.rsDefault.rsVer .rsArrowLeft { top: 0; left: 0; }
.rsDefault.rsVer .rsArrowRight { bottom: 0;  left: 0; }

.rsDefault.rsHor .rsArrowLeft { left: 0; top: 0; }
.rsDefault.rsHor .rsArrowRight { right: 0; top:0; }

.rsDefault .rsArrowIcn {		
	width: 32px;
	height: 32px;
	top: 50%;
	left: 50%;
	margin-top:-16px;	
	margin-left: -16px;

	position: absolute;	
	cursor: pointer;	
	background: url(//stack3.onptest.com/dist/OvernightPrintsUK/images/rs-default.0e40b16b.png);

	background-color: #000;
	background-color: rgba(0,0,0,0.75);
	*background-color: #000;
	
	border-radius: 2px;
}
.rsDefault .rsArrowIcn:hover {
	background-color: rgba(0,0,0,0.9);
}

.rsDefault.rsHor .rsArrowLeft .rsArrowIcn { background-position: -64px -32px; }
.rsDefault.rsHor .rsArrowRight .rsArrowIcn { background-position: -64px -64px; }

.rsDefault.rsVer .rsArrowLeft .rsArrowIcn { background-position: -96px -32px; }
.rsDefault.rsVer .rsArrowRight .rsArrowIcn { background-position: -96px -64px; }

.rsDefault .rsArrowDisabled .rsArrowIcn { opacity: .2; filter: alpha(opacity=20);  *display: none; }


/***************
*
*  2. Bullets
*
****************/

.rsDefault .rsBullets {
	position: absolute;
	z-index: 35;
	left: 0;
	bottom: 0;
	width: 100%;
	height: auto;
	margin: 0 auto; 

	background: #000;
	background: rgba(0,0,0,0.75);

	text-align: center;
	line-height: 8px;
	overflow: hidden;
}
.rsDefault .rsBullet {
	width: 8px;
	height: 8px;
	display: inline-block;
	*display:inline; 
	*zoom:1;
	padding: 6px 5px 6px;
}
.rsDefault .rsBullet span {
	display: block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #777;
	background: rgba(255,255,255,0.5);
}
.rsDefault .rsBullet.rsNavSelected span {
	background-color: #FFF;
}





/***************
*
*  3. Thumbnails
*
****************/

.rsDefault .rsThumbsHor {
	width: 100%;
	height: 72px;
}
.rsDefault .rsThumbsVer {
	width: 96px;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
}
.rsDefault.rsWithThumbsHor .rsThumbsContainer {
	position: relative;
	height: 100%;
}
.rsDefault.rsWithThumbsVer .rsThumbsContainer {
	position: relative;
	width: 100%;
}
.rsDefault .rsThumb {
	float: left;
	overflow: hidden;
	width: 96px;
	height: 72px;
}
.rsDefault .rsThumb img {
	width: 100%;
	height: 100%;
}
.rsDefault .rsThumb.rsNavSelected {
	background: #02874a;
}
.rsDefault .rsThumb.rsNavSelected img {
	opacity: 0.3;
	filter: alpha(opacity=30);
}
.rsDefault .rsTmb {
	display: block;
}

/* Thumbnails with text */
.rsDefault .rsTmb h5 {
	font-size: 16px;
	margin: 0;
	padding: 0;
	line-height: 20px;
	color: #FFF;
}
.rsDefault .rsTmb span {
	color: #DDD;
	margin: 0;
	padding: 0;
	font-size: 13px;
	line-height: 18px;
}



/* Thumbnails arrow icons */
.rsDefault .rsThumbsArrow {
	height: 100%;
	width: 20px;
	position: absolute;
	display: block;
	cursor: pointer;	
	z-index: 21;	
	background: #000;
	background: rgba(0,0,0,0.75);
}
.rsDefault .rsThumbsArrow:hover {
	background: rgba(0,0,0,0.9);
}
.rsDefault.rsWithThumbsVer .rsThumbsArrow {
	width: 100%;
	height: 20px;
}
.rsDefault.rsWithThumbsVer .rsThumbsArrowLeft { top: 0; left: 0; }
.rsDefault.rsWithThumbsVer .rsThumbsArrowRight { bottom: 0;  left: 0; }

.rsDefault.rsWithThumbsHor .rsThumbsArrowLeft { left: 0; top: 0; }
.rsDefault.rsWithThumbsHor .rsThumbsArrowRight { right: 0; top:0; }

.rsDefault .rsThumbsArrowIcn {		
	width: 16px;
	height: 16px;
	top: 50%;
	left: 50%;
	margin-top:-8px;	
	margin-left: -8px;
	position: absolute;	
	cursor: pointer;	
	background: url(//stack3.onptest.com/dist/OvernightPrintsUK/images/rs-default.0e40b16b.png);
}

.rsDefault.rsWithThumbsHor .rsThumbsArrowLeft .rsThumbsArrowIcn { background-position: -128px -32px; }
.rsDefault.rsWithThumbsHor .rsThumbsArrowRight .rsThumbsArrowIcn { background-position: -128px -48px; }

.rsDefault.rsWithThumbsVer .rsThumbsArrowLeft .rsThumbsArrowIcn { background-position: -144px -32px; }
.rsDefault.rsWithThumbsVer .rsThumbsArrowRight .rsThumbsArrowIcn { background-position: -144px -48px; }

.rsDefault .rsThumbsArrowDisabled { display: none !important; }

/* Thumbnails resizing on smaller screens */
@media screen and (min-width: 0px) and (max-width: 800px) {
	.rsDefault .rsThumb {
		width: 59px;
		height: 44px;
	}
	.rsDefault .rsThumbsHor {
		height: 44px;
	}
	.rsDefault .rsThumbsVer {
		width: 59px;
	}
}




/***************
*
*  4. Tabs
*
****************/

.rsDefault .rsTabs {
	width: 100%;
	height: auto;
	margin: 0 auto;
	text-align:center;
	overflow: hidden; padding-top: 12px; position: relative;
}
.rsDefault .rsTab {
	display: inline-block;
	cursor: pointer;
	text-align: center;
	height: auto;
	width: auto;
	color: #333;
	padding: 5px 13px 6px;
	min-width: 72px;
	border: 1px solid #D9D9DD;
	border-right: 1px solid #f5f5f5;
	text-decoration: none;

	background-color: #FFF;
	background-image: -webkit-linear-gradient(top, #fefefe, #f4f4f4); 
	background-image:    -moz-linear-gradient(top, #fefefe, #f4f4f4);
	background-image:         linear-gradient(to bottom, #fefefe, #f4f4f4);

	-webkit-box-shadow: inset 1px 0 0 #fff;
	box-shadow: inset 1px 0 0 #fff;

	*display:inline; 
	*zoom:1;
}
.rsDefault .rsTab:first-child {
	-webkit-border-top-left-radius: 4px;
	border-top-left-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.rsDefault .rsTab:last-child { 
	-webkit-border-top-right-radius: 4px;
	border-top-right-radius: 4px;
	-webkit-border-bottom-right-radius: 4px;
	border-bottom-right-radius: 4px;

	border-right:  1px solid #cfcfcf;
}
.rsDefault .rsTab:active { 
	border: 1px solid #D9D9DD;   
	background-color: #f4f4f4;
	box-shadow:  0 1px 4px rgba(0, 0, 0, 0.2) inset;
}
.rsDefault .rsTab.rsNavSelected { 
	color: #FFF;
	border: 1px solid #999;
	text-shadow: 1px 1px #838383;
	box-shadow: 0 1px 9px rgba(102, 102, 102, 0.65) inset;
	background: #ACACAC;
	background-image: -webkit-linear-gradient(top, #ACACAC, #BBB);
	background-image: -moz-llinear-gradient(top, #ACACAC, #BBB);
	background-image: linear-gradient(to bottom, #ACACAC, #BBB);
}





/***************
*
*  5. Fullscreen button
*
****************/

.rsDefault .rsFullscreenBtn {
	right: 0;
	top: 0;
	width: 44px;
	height: 44px;
	z-index: 22;
	display: block;
	position: absolute;
	cursor: pointer;
	
}
.rsDefault .rsFullscreenIcn {
	display: block;
	margin: 6px;
	width: 32px;
	height: 32px;

	background: url(//stack3.onptest.com/dist/OvernightPrintsUK/images/rs-default.0e40b16b.png) 0 0;
	background-color: #000;
	background-color: rgba(0,0,0,0.75);
	*background-color: #000;
	border-radius: 2px;

}
.rsDefault .rsFullscreenIcn:hover {
	background-color: rgba(0,0,0,0.9);
}
.rsDefault.rsFullscreen .rsFullscreenIcn {
	background-position: -32px 0;
}





/***************
*
*  6. Play/close video button
*
****************/

.rsDefault .rsPlayBtn {
	-webkit-tap-highlight-color:rgba(0,0,0,0.3);
	width:64px;
	height:64px;
	margin-left:-32px;
	margin-top:-32px;
	cursor: pointer;
}
.rsDefault .rsPlayBtnIcon {
	width:64px;
	display:block;
	height:64px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	
	-webkit-transition: .3s;
	-moz-transition: .3s;
	transition: .3s;

	background:url(//stack3.onptest.com/dist/OvernightPrintsUK/images/rs-default.0e40b16b.png) no-repeat 0 -32px;
	background-color: #000;
	background-color: rgba(0,0,0,0.75);
	*background-color: #000;
}
.rsDefault .rsPlayBtn:hover .rsPlayBtnIcon {
	background-color: rgba(0,0,0,0.9);
}
.rsDefault .rsBtnCenterer {
	position:absolute;
	left:50%;
	top:50%;
}
.rsDefault .rsCloseVideoBtn {
	right: 0;
	top: 0;
	width: 44px;
	height: 44px;
	z-index: 500;
	position: absolute;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	
}
.rsDefault .rsCloseVideoBtn.rsiOSBtn {
	top: -38px;
	right: -6px;
}

.rsDefault .rsCloseVideoIcn {
	margin: 6px;
	width: 32px;
	height: 32px;
	background: url(//stack3.onptest.com/dist/OvernightPrintsUK/images/rs-default.0e40b16b.png) -64px 0;
	background-color: #000;
	background-color: rgba(0,0,0,0.75);
	*background-color: #000;
}
.rsDefault .rsCloseVideoIcn:hover {
	background-color: rgba(0,0,0,0.9);
}



/***************
*
*  7. Preloader
*
****************/

.rsDefault .rsPreloader {
	width:20px;
	height:20px;
	background-image:url(//stack3.onptest.com/dist/OvernightPrintsUK/images/preloader-white.b79d10cf.gif);

	left:50%;
	top:50%;
	margin-left:-10px;
	margin-top:-10px;	
}




/***************
*
*  8. Global caption
*
****************/
.rsDefault .rsGCaption {
	position: absolute;
	float: none;
	bottom: 6px;
	left: 6px;
	text-align: left;

	background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.75);

	color: #FFF;
	padding: 2px 8px;
	width: auto;
	font-size: 12px;
	border-radius: 2px;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
/*
 *  Responsive Utilities
 */
#royalSlider-trimmer {
  overflow: hidden;
  height: 400px;
}
@media (max-width: 767px) {
  #royalSlider-trimmer {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  #royalSlider-trimmer {
    display: none;
  }
}
#royalSlider-trimmer .royalSlider-container {
  position: relative;
}
#royalSlider-trimmer .royalSlider-container .royalSlider-preload-image {
  position: absolute;
}
#royalSlider-trimmer .royalSlider-container .rsHor {
  max-height: 400px;
  overflow: hidden;
}
#royalSlider-trimmer .royalSlider-container .rsHor .rsContainer .rsArrowRight {
  right: 0;
}
.royalSlider {
  width: 100%;
  height: 100%;
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .royalSlider {
    height: 365px;
  }
}
@media (min-width: 1381px) {
  .royalSlider {
    height: 400px;
  }
}
.royalSlider a.img-as-bg {
  display: block;
  height: 100%;
  width: 100%;
}
.rsDefault,
.rsDefault .rsOverflow,
.rsDefault .rsSlide,
.rsDefault .rsVideoFrameHolder,
.rsDefault .rsThumbs {
  background: transparent;
}
.rsDefault .rsOverflow {
  overflow: hidden;
  max-height: 400px;
}
.rsDefault .rsArrowIcn {
  font-family: FontAwesome;
  color: rgba(0, 0, 0, 0.7);
  font-size: 32px;
  line-height: 32px;
  background: transparent;
  text-align: center;
}
.rsDefault .rsArrowIcn:hover {
  color: rgba(0, 0, 0, 0.9);
  background: transparent;
}
.rsDefault .rsArrow {
  z-index: 96;
  height: 100%;
  width: 44px;
  position: absolute;
  top: 0;
  cursor: pointer;
}
.rsDefault .rsArrowLeft {
  left: 0;
}
.rsDefault .rsArrowLeft .rsArrowIcn:after {
  content: "\f104";
}
.rsDefault .rsArrowRight {
  right: 0;
}
.rsDefault .rsArrowRight .rsArrowIcn:after {
  content: "\f105";
}
.rsDefault .rsArrowIcn {
  width: 32px;
  height: 32px;
  top: 50%;
  margin-top: -16px;
  position: absolute;
}
.rsDefault .rsNav {
  background: transparent;
}
.rsDefault .rsBullets {
  z-index: 97;
  bottom: 30px;
  position: absolute;
  top: 90%;
  width: 100%;
  text-align: center;
  min-height: 17px;
}
.rsDefault .rsBullet {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0 3px;
  display: inline-block;
}
.rsDefault .rsBullet span {
  background-image: url(/assets/images/site/onp_main_sprite.png);
  background-repeat: no-repeat;
  background-position: -240px -50px;
  width: 10px;
  height: 12px;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;
  display: block;
}
.rsDefault .rsBullet.rsNavSelected span {
  background-position: -256px -50px;
  background-color: transparent;
}

