.cookie-policy {
  bottom: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  background: #01aee3;
  z-index: 99;
}
.cookie-policy-desc {
  color: #fff;
}
@media (max-width: 767px) {
  .cookie-policy-desc {
    padding: 0 35px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cookie-policy-desc {
    padding: 0 35px;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .cookie-policy-desc {
    padding: 15px 35px;
  }
}
@media (min-width: 1381px) {
  .cookie-policy-desc {
    padding: 15px 35px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .cookie-policy-desc {
    font-size: 18px;
  }
}
@media (min-width: 1024px) and (max-width: 1380px) {
  .cookie-policy-desc {
    font-size: 18px;
  }
}
@media (min-width: 1381px) {
  .cookie-policy-desc {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .cookie-policy-desc {
    font-size: 14px;
  }
}
.cookie-policy-desc a {
  color: #ffc107;
}
.cookie-policy-close {
  cursor: pointer;
  font: 28px/28px 'Zapf Dingbats';
  height: 26px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 26px;
  color: #fff;
}
