@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/mixins.less';
@import (reference) '~resources/less/themes/base/mixins/grid.less';
@import (reference) '~resources/less/themes/base/mixins/responsive_utilities.less';
@import '~resources/less/themes/base/elements/design_face_type.less';

@blockImageMinHeight: 279px;
@tabletBlockImageMaxHeight: 317px;
@mobileBlockImageMaxHeight: 117px;

.featured-prods-title {
    text-align: center;
    color: @primaryGray;
    .featured-prods-title-hr {
        width: 100%;
        height: 2px;
        border-top: 1px solid @primaryGray;
        margin-top: 29px;
    }
    h3,
    .featured-prods-title-text {
        display: inline-block;
        margin: -31px auto 30px;
        padding: 0 20px;
        font-size: 36px;
        line-height: 35px;
        text-transform: uppercase;
        font-weight: bold;
        background-color: @bodyBackground;
    }
    .onp-style(
        @small: {
            display: none;
        }
    );
}

.featured-prods-dynamic {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: @blocksFontFamily;
    color: @primaryGray;
    &::after {
        content: '';
        flex: auto;
    }
    .onp-style(
        @big: {
            width: 102%;
            margin-left: -1%;
        }
    );
    .featured-prod-container {
        display: none;
        .onp-style(
            @xs: {
                vertical-align: top;
                margin: 10px 0;
            },
            @big: {
                padding: 1%;
            },
            @lg: {
                width: 25%;
            },
            @md: {
                width: 33.33333%;
            },
            @small: {
                width: 100%;
            }
        );
        &.show-on-desktop {
            .onp-style(
                @big: {display: block;}
            );
        }
        &.show-on-tablet {
            .onp-style(
                @small: {display: block;}
            );
        }
    }
    .featured-prod {
        margin-bottom: 50px;
        cursor: pointer;
        .onp-style(
            @xs: {
                margin: 0;
                border: 1px solid @lightBorderColor;
                padding: 5px 10px 0;
            },
            @big: {
                border: 1px solid @borderColor;
            }
        );
        &:hover {
            box-shadow: 0 0 @featuredProdShadowWidth 3px rgba(0,0,0,0.15);
        }
    }
    .block-main-image {
        overflow: hidden;
        .onp-style(
            @xs: {
                max-height: 165px;
            },
            @big: {
                min-height: @blockImageMinHeight
            }
        );
        img {
            width: 100%;
        }
        .tablet-img {
            .onp-style(
                @big: {
                    display: none;
                }
            );
            & + .desktop-img {
                .onp-style(
                    @small: {
                        display: none;
                    }
                );
            }
        }
    }
    .custom-block-main-image {
        text-align: center;
        overflow: hidden;
        background-color: @primaryGrayLighter;
        &::before {
            content: '';
            margin-top: -2px;
            display: block;
        }
        .onp-style(
            @big: {
                line-height: @blockImageMinHeight;
                height: @blockImageMinHeight;
            },
            @sm: {
                line-height: @tabletBlockImageMaxHeight;
                height: @tabletBlockImageMaxHeight
            },
            @xs: {
                line-height: @mobileBlockImageMaxHeight;
                height: @mobileBlockImageMaxHeight;
            }
        );
        img {
            margin-left: auto;
            margin-right: auto;
            max-height: 100%;
            max-width: 90%;
            border: 1px solid #ccc;
            vertical-align: middle;
        }
        .tablet-img {
            display: none;
            .onp-hidden(@big: true);
            & + .desktop-img {
                display: inline
            }
        }
    }
    .block-text {
        padding: 30px 30px 20px 20px;
        text-align: center;
        position: relative;
        .onp-style(
            @big: {
                height: 189px;
            },
            @small: {
                .centered(425px);
            },
            @xs: {
                min-height: 40px;
                padding: 0;
            }
        );
        h3,
        .block-title{
            display: inline-block;
            margin: 0 0 10px;
            font-size: 22px;
            font-weight: bold;
            text-transform: capitalize;
            .onp-style(
                @xs: {
                    font-size: @fontSizeLarge;
                    margin: 5px 0;
                }
            )
        }
    }
    .block-button {
        position: absolute;
        left: 50%;
        cursor: pointer;
        .gradient-form-left-to-right (#00aee1, #0098c7);
        border: 2px solid #9ed1f0;
        text-transform: uppercase;
        box-sizing: border-box;
        color: white;
        font-weight: normal;
        padding-top: 5px;
        line-height: 1.428571429;
        bottom: -18px;
        margin-left: -76px;
        width: 153px;
        height: 35px;
        font-size: 16px;
        .onp-style(
            @small: {
                display: none;
            }
        );
        &.block-button-1 {
            background: @primaryBlue;
            &:hover {
                background: linear-gradient(90deg,#01aee3 0,#0098c7);
            }
        }
        &.block-button-2 {
            background: #c60707;
            border-color: #ff0000;
            &:hover {
                background: #c60707;
            }
        }
        &.block-button-3 {
            background: #9dd1eF;
            &:hover {
                background: linear-gradient(90deg,#9dd1ef 0,#fff);
            }
        }
        &.block-button-4 {
            background: #155a94;
            border-color: #155a94;
        }
        &.block-button-5 {
            background: #f04702;
            border-color: #f04702;
        }
        &.block-button-6 {
            background: @primaryBlue;
            border-color: @primaryBlue;
            &:hover {
                background: @primaryBlueHover;
                border-color: @primaryBlueHover;
            }
        }
    }
    .block-description {
        font-size: @baseFontSize;
        line-height: @baseLineHeight;
    }
    .block-control {
        position: absolute;
        right: 0;
    }
}
