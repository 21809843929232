@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~resources/less/themes/base/mixins/mixins.less';
@import (reference) '~resources/less/themes/base/mixins/grid.less';
@import (reference) '~resources/less/themes/base/mixins/responsive_utilities.less';

body {
    font-size: @baseFontSize;
}

.body-content {
    display: flex;
    flex-direction: column;
}

#homepage {
    margin-bottom: 0;
}

section.main-seo {
    .onp-style(
        @small: {
            margin-top: 15px;
            margin-bottom: 10px;
            position: relative;
        },
        @big: {
            .onp-row();
            margin-top: 24px;
            margin-bottom: 20px;
        }
    );
    .main-seo-info {
        .onp-column(@md: 12, @lg: 12);
    }
    h4 {
        font-size: 120%;
        text-transform: capitalize;
        margin: 0;
    }
    p {
        font-size: @baseFontSize;
        line-height: 20px;
        margin: 0 0 10px;
    }
}

.content-covid {
    h3 {
        text-align: center;
        margin-bottom: 10px;
    }
}

.content-social {
    margin-top: 20px;
    padding-top: 10px;
    text-align: center;

    .partnering-providers-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        /* social media button intra-spacing */
        > div {
            padding: 0 7px;
        }
    }
}

// Mobile/Tablet content
.content-social {
    .onp-style(
        @small: {
            display: none;
        }
    );
}

section.main-seo-header {
    .onp-style(
        @small: {
            order: -1;
        }
    );

    h1 {
        .title-tag;
        text-align: center;
        font-family: @latoFontFamily;
        text-transform: uppercase;
        .onp-style(
            @small: {
                font-size: 125%;
                margin: 20px 0 10px 0;
            },
            @big: {
                font-size: 180%;
                margin: 25px 0;
            }
        );
        span {
            display: inline-block;
        }
    }
}
section.main-seo {
    .onp-style(
        @xs: {
            .seo-read-more {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding: 60px 0 20px 0;
                background-image: linear-gradient(to bottom, rgba(255,255,255,0.3), #fff);
                color: @primaryBlue;
                text-decoration: none;
            }
        },
        @big: {
            .onp-row();
            margin-top: 24px;
            margin-bottom: 20px;
        },
        @non-xs: {
            .seo-read-more {
                display: none;
            }
        }
    );
    .main-seo-info {
        .onp-style(
            @xs: {
                &.xs-seo-collapsed {
                    max-height: 360px;
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 30px;
                }
            }
        );
    }
    h4 {
        .onp-style(
            @xs: {
                font-size: @baseFontSize;
            },
            @non-xs: {
                font-size: 120%;
            }
        );
    }
}

.home-page-slider {
    .onp-container();
    .onp-style(
        @non-xs: {
            margin-top: 10px;
        }
    );
}
