@import (reference) '~resources/less/themes/base/variables.less';
@import (reference) '~baseTheme/_common/base/mixins.less';
@import (reference) '~resources/less/themes/base/mixins/grid.less';
@import '~resources/less/themes/base/elements/simplified/text.less';
@import '~resources/less/themes/base/elements/simplified/button_arrow.less';
@import '~resources/less/themes/base/elements/simplified/button_secondary.less';

// styles for new mobile version
.onp-style (
    @xs: {
        .mobile-adjusted {
            #footer-main {
                display: none;
            }
        }
    }
);

.footer-blocks-container {
    .onp-container();
}

#footer-main {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #e4e4e4;
    color: #777;
    font-size: @baseFontSize;
    position: relative;
    margin-top: 20px;
    .onp-style (
        @small: {
            border-top: 1px solid @primaryGrayLight;
            background: @primaryGrayLighter;
            padding-top: 0;
            margin-top: 0;
        }
    );

    a {
        color: @gray-light;
        border: none;
        text-decoration: none;
    }

    h3,
    .footer-block-title {
        font-family: @headerFontFamily;
        margin: 0;
        font-size: 15px;
        font-weight: normal;
        line-height: 1em;
        color: @primaryBlue;
        padding: 9px 0 5px;
        display: block;
        .onp-style(
            @small: {
                text-transform: uppercase;
                color: #777;
                font-weight: bold;
                padding-left: 20px;
            }
        );
        .onp-style(
            @xs: {
                font-size: @baseFontSize;
                padding-left: 16px;
                text-indent: 0;
                line-height: 140%;
            }
        );
    }

    h4 {
        margin: 0;
        font-size: 1.29em;
        font-weight: normal;
        line-height: 1em;
        color: @primaryBlue;
        padding: 2px 0 15px 0;
    }

    p {
        margin-bottom: 2px;
    }

    .follow-toolbox {
        padding-bottom: 20px;
        margin-left: 0;
        .onp-style (
            @small: {
                padding-bottom: 0;
            }
        );

        a {
            .icon-wrapper {
                cursor: pointer;
                display: inline-block;
                overflow: hidden;
                line-height: 32px;
                height: 32px;
                width: 32px;
                .onp-style (
                    @xs: {
                        height: 24px;
                        width: 24px;
                        line-height: 24px;
                    }
                );
            }

            margin-bottom: 5px;
            line-height: initial;
        }

        .button-follow {
            padding: 0 2px;
            float: left;
            cursor: pointer;
        }

        .icon {
            width: 32px;
            height: 32px;
            .onp-style (
                @xs: {
                    height: 24px;
                    width: 24px;
                    line-height: 24px;
                }
            );
            fill: #fff;
            border: 0;
        }

        .follow-label {
            display: none;
        }

        .follow-clear {
            clear: both;
        }

        .button_facebook_follow .icon-wrapper {
            background-color: rgb(59, 89, 152);
        }

        .button_twitter_follow .icon-wrapper {
            background-color: rgb(29, 161, 242);
        }

        .button_youtube_follow .icon-wrapper {
            background-color: rgb(205, 32, 31);
        }

        .button_vimeo_follow .icon-wrapper {
            background-color: rgb(26, 183, 234);
        }

        .button_pinterest_follow .icon-wrapper {
            background-color: rgb(203, 32, 39);
        }

        .button_instagram_follow .icon-wrapper {
            background-color: rgb(224, 53, 102);
        }
    }

    .footer-resources {
        .onp-style (
            @xs: {
                clear: left;
            }
        )
    }

    .signup-container {
        h3 {
            display: block;
            padding-bottom: 6px;
            width: 250px;
            .onp-hidden(@small: true);
        }
    }

    .footer-blocks {
        display: flex;
        justify-content: space-between;
        .onp-style (
            @small: {
                flex-direction: column;
                margin-bottom: 6px;
            }
        );
    }

    .footer-social {
        margin-top: 2px;
        .footer-block {
            flex-basis: 48%;
            .onp-style(
                @big: {
                    border-top: 1px solid @primaryGrayLight2;
                }
            );
        }
        .onp-style(
            @small: {
                display: flex;
                flex-direction: column;
                .social-container {
                    order: 2;
                    border-bottom: none;
                    border-top: 1px solid @primaryGrayLight2;
                    padding-top: 10px;
                    margin-top: 10px;
                    h3 {
                        display: none;
                    }
                    .copyright {
                        margin: 4px 0 10px;
                    }
                }
                .signup-container {
                    order: 1;
                }
            }
        );
    }
}

div.form-sign-wrap {
    width: 250px;
    float: left;
    .onp-style(
        @small: {
            width: 100%;
        }
    );

    h3 {
        .onp-style(
            @small: {
                display: none;
            }
        );
    }
}

.footer-seal {
    border: none !important;
    font-family: @latoFontFamily;
    .onp-style(
        @lg: {
            margin-left: 92px;
        }
    );
    float: left;
    margin-top: 10px;

    a.shopper-link img {
        border: 0;
    }

    .onp-hidden(@small: true);

    .shopper-approved-footer {
        margin-top: 8px;
        width: 180px;
        line-height: 100%;
    }
}

.footer-collapsed-dots {
    text-align: center;
    .onp-hidden(@big: true);
}



.footer-menu input{
    display: none;
}

.footer-block {
    .onp-style(
        @xs: {
            padding-left: 0;
        }
    )
}

.footer-menu{
    .footer-block {
        flex-basis: 22%;
        .onp-style (
            @big: {
                border-top: 1px solid @primaryGrayLight2;
            },
            @small: {
                border-bottom: 1px solid @primaryGrayLight2;
            }
        );
    }
    .menu-item-content {
        list-style: none;
        margin: 0 0 0 2px;
        padding: 0;
        .onp-style (
            @small: {
                background: transparent;
            }
        );

        li {
            line-height: 1.58em;
        }
    }
}

.onp-style(
    @small: {

        .footer-menu label{
            position: relative;
            z-index: 20;
            display: block;
            cursor: pointer;
        }

        .footer-menu label:before {
            content: '\f106';
            font-family: FontAwesome;
            position: absolute;
            width: 24px;
            height: 24px;
            height: 24px;
            top: 8px;
        }

        .footer-menu input:checked + label:before{
            content: '\f107';
        }

        .footer-menu .menu-item-content{
            margin: 0 0 0 14px;
            overflow: hidden;
            height: 0px;
            position: relative;
            z-index: 10;
            transition: height 0.3s ease-in-out;
        }

        .footer-menu input:checked ~ .menu-item-content{
            height: auto;
        }
    }
);
