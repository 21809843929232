@import '../../variables.less';
@import "../../mixins/mixins.less";
@import "../../mixins/grid.less";
@import "../../mixins/responsive_utilities.less";

#royalSlider-trimmer {
    overflow: hidden;
    height: 400px;
    .onp-style(
        @small: {
            display: none;
        }
    );
    .royalSlider-container {
        position: relative;
        .royalSlider-preload-image {
            position: absolute;
        }
        .rsHor {
            max-height: 400px;
            overflow: hidden;
            .rsContainer {
                .rsArrowRight {
                    right: 0;
                }
            }
        }
    }
}

.royalSlider {
    width: 100%;
    height: 100%;

    .onp-style(
        @lg: {
            height: @bannerLgHeight;
        },
        @md: {
            height:  @bannerMdHeight;
        }
    );

    a.img-as-bg {
        display: block;
        height: 100%;
        width: 100%;
    }
}

.rsDefault {
    &, .rsOverflow, .rsSlide, .rsVideoFrameHolder, .rsThumbs {
        background: transparent;
    }
    .rsOverflow {
        overflow: hidden;
        max-height: 400px;
    }
    .rsArrowIcn {
        font-family: FontAwesome;
        color: rgba(0,0,0,.7);
        font-size: 32px;
        line-height: 32px;
        background: transparent;
        text-align: center;
        &:hover {
            color: rgba(0,0,0,.9);
            background: transparent;
        }
    }
    .rsArrow {
        z-index: @sliderArrowZIndex;
        height: 100%;
        width: 44px;
        position: absolute;
        top: 0;
        cursor: pointer;
    }
    .rsArrowLeft {
        left: 0;
        .rsArrowIcn:after {
            content: "\f104";
        }
    }
    .rsArrowRight {
        right: 0;
        .rsArrowIcn:after {
            content: "\f105";
        }
    }
    .rsArrowIcn {
        width: 32px;
        height: 32px;
        top: 50%;
        margin-top: -16px;
        position: absolute;
    }
    .rsNav {
        background: transparent;
    }
    .rsBullets {
        z-index: @menuZIndex - 1;
        bottom: 30px;
        position: absolute;
        top: 90%;
        width: 100%;
        text-align: center;
        min-height: 17px;
    }
    .rsBullet {
        width: auto;
        height: auto;
        padding: 0;
        margin: 0 3px;
        display: inline-block;
        span {
            .main-sprite();
            background-position: -240px -50px;
            width: 10px;
            height: 12px;
            border: 0;
            border-radius: 0;
            cursor: pointer;
            background-color: transparent;
            display: block;
        }
        &.rsNavSelected span {
            background-position: -256px -50px;
            background-color: transparent;
        }
    }
}
