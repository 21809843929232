#vip-tooltip {
  text-align: center;
}
#vip-tooltip .vip-popup-star {
  margin-top: -60px;
}
#vip-tooltip.mobile .vip-popup-star {
  margin-top: 0;
}
#vip-tooltip h3 {
  font-size: 17.5px;
}
#vip-tooltip .dialog-popup-body {
  padding-bottom: 12px;
  padding-top: 60px;
}
#vip-tooltip .dialog-popup-container {
  position: absolute;
  z-index: 1;
  right: 8px;
  top: 84px;
  overflow: visible;
}
#vip-tooltip .dialog-popup-container::before {
  border: solid transparent;
  content: " ";
  position: absolute;
  border-bottom-color: #fff;
  border-width: 28px;
  margin-left: -106px;
  top: -46px;
}
